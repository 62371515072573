import React from 'react'
import { useEffect } from 'react'
import { BrowserRouter, Routes, Route, useNavigate, Navigate } from 'react-router-dom'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { initReactI18next, useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import i18n from 'i18next'
import i18nconfig from './i18nconfig'

import { setToken } from './services/graphql'
import SettingRoutes from './views/setting'
import Menu from './components/menu'
import Statistic from './views/stat'
import CatalogRoutes from './views/catalog'
import store from './store'
import { Action, AppState, DashboardView, MenuState } from './store/types'
import ActivityRoutes from './views/activity'
import initApp from './actions/initApp'
import ConnectionRoutes from './views/connection'
import PostInvitation from './views/connection/postInvitation'
import ToApp from './views/toIpad'
import { popInvitation, setCompanyID, setInvitation } from './services/localStorage'
import { updateModal } from './actions/modal'
import ErrorModal from './components/newComponents/modal/error'
import ListCustomers from './views/customer/list'
import { currentAccount, flatFileModal, getAppState, isDrawerOpen } from './store/selector'
import Loader from './components/newComponents/loader'
import { callService } from './services'
import { UpdateAccountInvitationInput } from './API'
import { acceptInvitation } from './graphql/custom/mutations'
import Modal from './components/newComponents/modal'
import AccountRoutes from './views/account'
import toggleMenu from './actions/menu'
import Toaster from './components/newComponents/toaster'

import styles from './App.module.css'
import FlatfileImport from './views/setting/import/modal/flatfile'
import { Helmet } from 'react-helmet';


i18n.use(initReactI18next).init(i18nconfig)


const AppRoutes = () => {
  const appState = useSelector(getAppState)
  const account = useSelector(currentAccount)
  const drawerOpen = useSelector(isDrawerOpen)
  const flatFile = useSelector(flatFileModal);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isAuthenticated, isLoading, getAccessTokenSilently, getAccessTokenWithPopup, logout } = useAuth0()
  const url = window.location.pathname
  const location = window.location.hostname
  const inviteID = url.includes('/invitation/') ? url.split('/').pop() : ''
  const isSignUp = url.includes('/signUp')
  const isForgotPassword = url.includes('/forgotPassword')
  const mobileEvent = window.matchMedia('(max-width: 767px)')
  const tabletEvent = window.matchMedia('(min-width: 768px) and (max-width: 1023px)')
  const desktopEvent = window.matchMedia('(min-width: 1024px)')
  const { t } = useTranslation()

  const startApp = async (token: string) => {
    setToken(token, getAccessTokenSilently, logout, dispatch)
    const invitationID = inviteID !== '' ? inviteID : popInvitation()
    if (invitationID) {
      const data = await callService<{ input: UpdateAccountInvitationInput }>(
        { input: { id: invitationID } },
        acceptInvitation,
        'acceptInvitation'
      )
      if (!data.errors) {
        setCompanyID(data.data.companyID)
        navigate('/postInvitation')
      } else updateModal(dispatch, true, ErrorModal, () => { })
    }
    initApp(dispatch, t)
  }

  /**
   *
   * This method listen to the event getDashboardView and change the store if this variable change
   *
   * @param type - DashboardView type
   * @param data - An object with a boolean to know if we are on mobile view or not
   *
   * @returns - void
   *
   * @author - Jennifer Charlois
   *
   */
  const listenMobileView = (type: DashboardView) => (data: any) => {
    if (data.matches) {
      if (type === DashboardView.MOBILE) toggleMenu(dispatch, { state: MenuState.CLOSE })
      else toggleMenu(dispatch, { state: MenuState.EXPAND })
      dispatch({
        type: Action.FETCH_ITEM,
        payload: { items: type, type: 'dashboardView' },
      })
    }
  }

  /**
   *
   * Auth0 does not authorize localhost to skip user consent
   * We must call getAccessTokenWithPopup in order to log in and use it
   *
   * @returns - void
   *
   * @author - Arthur Escriou x Jennifer Charlois
   *
   */
  useEffect(() => {
    if (!isLoading) {
      if (location === 'localhost') {
        getAccessTokenWithPopup()
          .then((token) => {
            isAuthenticated && startApp(token)
          })
          .catch(() => {
            if (inviteID) setInvitation(inviteID)
            navigate(isSignUp ? '/signUp' : isForgotPassword ? '/forgotPassword' : '/login')
          })
      } else {
        getAccessTokenSilently()
          .then((token) => {
            isAuthenticated && startApp(token)
          })
          .catch(() => {
            if (inviteID) setInvitation(inviteID)
            navigate(isSignUp ? '/signUp' : isForgotPassword ? '/forgotPassword' : '/login')
          })
      }
    }
    if (mobileEvent.matches) listenMobileView(DashboardView.MOBILE)(mobileEvent)
    if (tabletEvent.matches) listenMobileView(DashboardView.TABLET)(tabletEvent)
    mobileEvent.addEventListener('change', listenMobileView(DashboardView.MOBILE))
    tabletEvent.addEventListener('change', listenMobileView(DashboardView.TABLET))
    desktopEvent.addEventListener('change', listenMobileView(DashboardView.DESKTOP))

    return () => {
      mobileEvent.removeEventListener('change', listenMobileView(DashboardView.MOBILE))
      tabletEvent.removeEventListener('change', listenMobileView(DashboardView.TABLET))
      desktopEvent.removeEventListener('change', listenMobileView(DashboardView.DESKTOP))
    }
  }, [isLoading, isAuthenticated])

  useEffect(() => {
    window.intercomSettings = {
      api_base: 'https://api-iam.eu.intercom.io',
      app_id: 'quoar4k0',
      horizontal_padding: drawerOpen ? 420 : 0,
      user_id: account.id,
      user_hash: account.intercomHMAC,
    }
    window.Intercom('boot', {
      app_id: 'quoar4k0',
    })
  }, [drawerOpen, account.id, account.intercomHMAC])

  return account.id ? (
    <div className={styles.App}>
      {appState === AppState.LOADING ? (
        <Loader />
      ) : appState === AppState.LOADED ? (
        <>
          <Menu />
          {flatFile.flatfileData.spaceProps && <FlatfileImport />}
          <Routes>
            <Route path="catalog/*" element={<CatalogRoutes />} />
            <Route path="activity/*" element={<ActivityRoutes />} />
            <Route path="customers" element={<ListCustomers />} />
            <Route path="statistic/*" element={<Statistic />} />
            <Route path="settings/*" element={<SettingRoutes />} />
            <Route path="account/*" element={<AccountRoutes />} />
            <Route path="*" element={<Navigate replace to="/statistic/general" />} />
          </Routes>
        </>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <>
      <ConnectionRoutes />
    </>
  )
}

const App = () => (
  <Provider store={store}>
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <Modal />
    <Toaster />
    <BrowserRouter>
      <Routes>
        <Route path="postInvitation" element={<PostInvitation />} />
        <Route path="appRedirect" element={<ToApp />} />
        <Route path="*" element={<AppRoutes />} />
      </Routes>
    </BrowserRouter>
  </Provider>
)

export default App
