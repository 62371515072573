const totals = `
  total
  totalTax
  totalTaxFree
`;

const content = `
  general {
    content {
      nbSales
      totals {
        ${totals}
      }
      average {
        ${totals}
      }
      index
      margin
    }
    histogram {
      id
      content {
        nbSales
        totals {
          ${totals}
        }
      }
    }
  }
  payments {
    totals {
      nbPayments
      nbPositivePayments
      nbNegativePayments
      totalsDetail {
        totals {
          ${totals}
        }
        sellTotals {
          ${totals}
        }
        refundTotals {
          ${totals}
        }
      }
    }
    payments {
      id
      name
      totals {
        nbPayments
        nbPositivePayments
        nbNegativePayments
        totalsDetail {
          totals {
            ${totals}
          }
          sellTotals {
            ${totals}
          }
          refundTotals {
            ${totals}
          }
        }
      }
    }
  }
  taxes {
    totals {
      ${totals}
    }
    taxes {
      id
      totals {
        ${totals}
      }
    }
  }
  categories {
    quantity
    totals {
      ${totals}
    }
    margin
    categories {
      id
      name
      quantity
      totals {
        ${totals}
      }
      margin
    }
  }
  products {
    quantity
    totals {
      ${totals}
    }
    margin
    products {
      id
      name
      quantity
      totals {
        ${totals}
      }
      margin
      variants {
        id
        name
        quantity
        totals {
          ${totals}
        }
        margin
      }
    }
  }
`;

export const stat = `
  content {
    ${content}
  }
  byShop {
    id
    content {
      ${content}
    }
  }
`;

export const customField = `
  id
  updatedAt
  createdAt
  createdByID
  deprecated
  catalogID
  test
  objectType
  valueType
  key
  name
`;

export const productCustomField = `
  id
  updatedAt
  createdAt
  deprecated
  catalogID
  test
  productID
  customFieldID
  customFieldKey
  value
`;

export const categoryCustomField = `
  id
  updatedAt
  createdAt
  deprecated
  catalogID
  test
  categoryID
  customFieldID
  customFieldKey
  value
`;

export const packCustomField = `
  id
  updatedAt
  createdAt
  deprecated
  catalogID
  test
  packID
  customFieldID
  customFieldKey
  value
`;

export const cashbookCustomField = `
  customFieldID
  name
  valueType
  value
`;

export const tag = `
  id
  updatedAt
  createdAt
  createdByID
  deprecated
  catalogID
  test
  name
`;

export const productTag = `
  id
  updatedAt
  createdAt
  deprecated
  catalogID
  test
  productID
  tagID
`;

export const company = `
  id
  name
  address
  currency
  currencyDecimals
  createdAt
  updatedAt
`;

export const shop = `
  id
  createdAt
  updatedAt
  companyID
  company {
    ${company}
  }
  name
  address {
    address
    zipcode
    city
    country
  }
  logo
  siret
  vat
  phone
  footnote
  websiteURL
  brandName
  email
`;

export const shopName = `
  id
  companyID
  company {
    id
    name
    address
    currency
    currencyDecimals
  }
  name
`;

export const inventoryQuantities = `
  id
  shopID
  test
  productID
  variantID
  quantity
  resetDate
`;

const totalsDetail = `
  totals {
    total
    totalTaxFree
    totalTax
  }
  sellTotals {
    total
    totalTaxFree
    totalTax
  }
  refundTotals {
    total
    totalTaxFree
    totalTax
  }
`;

export const productList = `
  id
  updatedAt
  createdAt
  deprecated
  catalogID
  test
  name
  color
  iconText
  photo
  categoryID
  category {
    id
    updatedAt
    createdAt
    deprecated
    catalogID
    name
  }
  taxID
  tax {
    id
    updatedAt
    createdAt
    deprecated
    catalogID
    rate
  }
  buyPrice
  sellPrice
  barcode
  reference
  variations {
    name
    options
  }
  variantsCount
  variants {
    nextToken
  }
  tags {
    items {
      tag {
        ${tag}
      }
    }
    nextToken
  }
  customFields {
    items {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      productID
      customFieldID
      value
      customField {
        ${customField}
      }
    }
  }
  inventoryQuantities {
    ${inventoryQuantities}
  }
`;

export const productLight = `
  id
  updatedAt
  createdAt
  deprecated
  catalogID
  test
  name
  color
  iconText
  photo
  categoryID
  category {
    id
    updatedAt
    createdAt
    deprecated
    catalogID
    name
  }
  taxID
  tax {
    id
    updatedAt
    createdAt
    deprecated
    catalogID
    rate
  }
  buyPrice
  sellPrice
  barcode
  reference
  variantsCount
  variations {
    name
    options
  }
  customFields {
    items {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      productID
      customFieldID
      value
      customField {
        ${customField}
      }
    }
  }
  inventoryQuantities {
    ${inventoryQuantities}
  }
  tags {
    items {
      tag {
        ${tag}
      }
    }
    nextToken
  }
`;

// export const productSubscription = `
//   id
//   updatedAt
//   createdAt
//   deprecated
//   catalogID
//   test
//   name
//   color
//   iconText
//   photo
//   categoryID
//   variantsCount
//   taxID
//   buyPrice
//   sellPrice
//   barcode
//   reference
//   variations {
//     name
//     options
//   }
//   variantsCount
// `
export const productSubscription = `
  id
  updatedAt
  createdAt
  deprecated
  catalogID
  test
  name
  color
  iconText
  photo
  categoryID
  variantsCount
  category {
    id
    updatedAt
    createdAt
    deprecated
    catalogID
    name
    color
    iconText
    photo
  }
  taxID
  tax {
    id
    updatedAt
    createdAt
    deprecated
    catalogID
    rate
  }
  buyPrice
  sellPrice
  barcode
  reference
  variations {
    name
    options
  }
  variantsCount
  options {
    items {
      option {
        id
        name
        values {
          name
          price
        }
      }
    }
    nextToken
  }
  tags {
    items {
      tag {
        ${tag}
      }
    }
    nextToken
  }
  customFields {
    items {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      productID
      customFieldID
      value
      customField {
        ${customField}
      }
    }
  }
  inventoryQuantities {
    ${inventoryQuantities}
  }
`;

export const productGet = `
  id
  updatedAt
  createdAt
  deprecated
  catalogID
  test
  name
  color
  iconText
  photo
  categoryID
  variantsCount
  category {
    id
    updatedAt
    createdAt
    deprecated
    catalogID
    name
    color
    iconText
    photo
  }
  taxID
  tax {
    id
    updatedAt
    createdAt
    deprecated
    catalogID
    rate
  }
  buyPrice
  sellPrice
  barcode
  reference
  variations {
    name
    options
  }
  variantsCount
  variants(limit: 10) {
    items {
      id
      productID
      createdAt
      variantID
      order
      options {
        name
        value
      }
      taxID
      tax {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        rate
      }
      buyPrice
      sellPrice
      barcode
      reference
      inventoryQuantities {
        ${inventoryQuantities}
      }
    }
    nextToken
  }
  options {
    items {
      option {
        id
        name
        values {
          name
          price
        }
      }
    }
    nextToken
  }
  tags {
    items {
      tag {
        ${tag}
      }
    }
    nextToken
  }
  customFields {
    items {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      productID
      customFieldID
      value
      customField {
        ${customField}
      }
    }
  }
  inventoryQuantities {
    ${inventoryQuantities}
  }
`;

export const productGetForMovement = `
  id
  updatedAt
  createdAt
  deprecated
  catalogID
  test
  name
  color
  iconText
  photo
  categoryID
  variantsCount
  category {
    id
    updatedAt
    createdAt
    deprecated
    catalogID
    name
    color
    iconText
    photo
  }
  taxID
  tax {
    id
    updatedAt
    createdAt
    deprecated
    catalogID
    rate
  }
  buyPrice
  sellPrice
  barcode
  reference
  variations {
    name
    options
  }
  variantsCount
  variants {
    items {
      id
      productID
      createdAt
      variantID
      order
      options {
        name
        value
      }
      taxID
      buyPrice
      sellPrice
      barcode
      reference
      inventoryQuantities {
        ${inventoryQuantities}
      }
    }
    nextToken
  }
  options {
    items {
      option {
        id
        name
        values {
          name
          price
        }
      }
    }
    nextToken
  }
  tags {
    items {
      tag {
        ${tag}
      }
    }
    nextToken
  }
  customFields {
    items {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      productID
      customFieldID
      value
      customField {
        ${customField}
      }
    }
  }
  inventoryQuantities {
    ${inventoryQuantities}
  }
`;

export const productStats = `
  stats(
    shopIDs: $shopIDs,
    startDate: $startDate,
    endDate: $endDate,
    interval: $interval
  ) {
    totalsDetail {
      ${totalsDetail}
    }
    margin
    quantity
    histogram {
      id
      totalsDetail {
        ${totalsDetail}
      }
    }
  }
`;

export const inventoryStats = `
  inventoryStats(shopIDs: $shopIDs, date: $date) {
    content {
      quantity
      value
    }
    byShop {
      id
      content {
        quantity
        value
      }
    }
  }
`;

const productVariant = `
  updatedAt
  createdAt
  deprecated
  id
  productID
  variantID
  order
  catalogID
  options {
    name
    value
  }
  taxID
  buyPrice
  sellPrice
  barcode
  reference
`;

export const variation = `
  catalogID
  test
  productID
  variations {
    name
    options
  }
  insertions {
    ${productVariant}
  }
  modifications {
    ${productVariant}
  }
  deletions {
    ${productVariant}
  }
  product {
    id
    updatedAt
    createdAt
    deprecated
    catalogID
    test
    name
    color
    iconText
    photo
    categoryID
    taxID
    buyPrice
    sellPrice
    barcode
    reference
    variations {
      name
      options
    }
    variantsCount
  }
`;

export const inventoryProductMovement = `
  id
  updatedAt
  createdAt
  inventoryMovementID
  productID
  variantID
  details {
    product {
      name
      sellPrice
      category {
        name
      }
      reference
      barcode
    }
    variant {
      nameShort
      sellPrice
      reference
      barcode
    }
  }
  quantity
  price
`;

export const inventoryMovementList = `
  id
  test
  companyID
  updatedAt
  createdAt
  state
  stateDate
  motive
  origin
  originID
  destination
  destinationID
  totalQuantity
`;

export const inventoryMovementProductGet = `
  items {
    id
    productID
    quantity
    variants(limit: 5000) {
      items {
        id
        variantID
        quantity
        details {
          barcode
          reference
          nameShort
        }
        price
        createdAt
        updatedAt
        inventoryMovementProductID
        productID
      }
      nextToken
    }
    details {
      barcode
      category {
        name
      }
      name
      reference
    }
    price
    createdAt
    inventoryMovementID
    updatedAt
  }
  nextToken
`;

export const inventoryMovementGet = `
  id
  test
  companyID
  updatedAt
  createdAt
  state
  stateDate
  motive
  origin
  originID
  destination
  destinationID
  totalQuantity
  movements {
    items {
      ${inventoryProductMovement}
    }
    nextToken
  }
`;

export const discount = `
  id
  updatedAt
  createdAt
  deprecated
  catalogID
  test
  name
  color
  type
  rate
`;

export const category = `
  id
  updatedAt
  createdAt
  deprecated
  catalogID
  test
  photo
  name
  color
  iconText
  customFields {
    items {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      categoryID
      customFieldID
      value
      customField {
        ${customField}
      }
    }
  }
`;

export const categoryWithStats = `
  stats(
    shopIDs: $shopIDs,
    startDate: $startDate,
    endDate: $endDate,
    interval: $interval
  ) {
    totalsDetail {
      ${totalsDetail}
    }
    quantity
    histogram {
      id
      totalsDetail {
        ${totalsDetail}
      }
    }
  }
`;

export const pack = `
  id
  updatedAt
  createdAt
  deprecated
  catalogID
  test
  name
  color
  iconText
  photo
  barcode
  reference
  sellPrice
  packVariations {
    options {
      productID
      quantity
      additionalPrice
    }
  }
  customFields {
    items {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      packID
      customFieldID
      value
      customField {
        ${customField}
      }
    }
  }
`;

export const packWithStats = `
  stats(
    shopIDs: $shopIDs,
    startDate: $startDate,
    endDate: $endDate,
    interval: $interval
  ) {
    totalsDetail {
      ${totalsDetail}
    }
    quantity
    histogram {
      id
      totalsDetail {
        ${totalsDetail}
      }
    }
  }
`;

export const option = `
  id
  updatedAt
  createdAt
  catalogID
  test
  name
  values {
    name
    price
  }
`;

export const optionWithStats = `
  stats(
    shopIDs: $shopIDs,
    startDate: $startDate,
    endDate: $endDate,
    interval: $interval
  ) {
    quantity
    totalsDetail {
      ${totalsDetail}
    }
    histogram {
      id
      totalsDetail {
        ${totalsDetail}
      }
    }
  }
`;

export const productOption = `
  id
  updatedAt
  createdAt
  deprecated
  catalogID
  test
  productID
  optionID
`;

export const discountWithStats = `
  stats(
    shopIDs: $shopIDs,
    startDate: $startDate,
    endDate: $endDate,
    interval: $interval
  ) {
    amount
    quantity
    histogram {
      id
      amount
    }
  }
`;

export const tax = `
  id
  updatedAt
  createdAt
  deprecated
  catalogID
  test
  rate
`;

const seller = `
  id
  updatedAt
  createdAt
  firstName
  lastName
  color
  iconText
  email
`;

export const cashbookList = `
  id
  updatedAt
  createdAt
  companyID
  shopID
  test
  sourceID
  sourceName
  number
  state
  openedAt
  closedAt
  note
  movements {
    type
    amount
    motive
    createdAt
  }
  openingSellerID
  closingSellerID
  totalExpected
  totalDifference
  customFields {
    ${cashbookCustomField}
  }
`;

export const cashbookGet = `
  id
  updatedAt
  createdAt
  companyID
  shopID
  test
  sourceID
  sourceName
  number
  state
  openedAt
  closedAt
  note
  movements {
    type
    amount
    motive
    createdAt
  }
  openingSellerID
  closingSellerID
  openingSeller {
    ${seller}
  }
  closingSeller {
    ${seller}
  }
  openingCashFund {
    paymentMethodID
    paymentMethodName
    paymentMethodType
    total
  }
  closingCashFund {
    paymentMethodID
    paymentMethodName
    paymentMethodType
    total
  }
  totalExpected
  totalDifference
  customFields {
    ${cashbookCustomField}
  }
`;

export const cashbookWithStats = `
  ${cashbookGet}
  stats {
    totalsDetail {
      ${totalsDetail}
    }
    taxes {
      id
      totals {
        total
        totalTax
        totalTaxFree
      }
    }
    payments {
      id
      detail {
        amount
        quantity
      }
      sellDetail {
        amount
        quantity
      }
      refundDetail {
        amount
        quantity
      }
    }
  }
`;

export const customerMovement = `
  id
  updatedAt
  createdAt
  deprecated
  companyID
  test
  customerID
  state
  type
  amount
  saleID
  paymentID
  movementInDetails {
    paymentMethodID
    paymentMethodName
    paymentMethodType
  }
`;

export const customerList = `
  id
  updatedAt
  createdAt
  deprecated
  color
  iconText
  companyID
  test
  firstName
  lastName
  email
  phone
  companyName
  address {
    address
    zipcode
    city
    country
  }
  note
  fidelityCardNumber
  customFields {
    items {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      customerID
      customFieldID
      value
      customField {
        ${customField}
      }
    }
  }
`;

export const customerGet = `
  id
  updatedAt
  createdAt
  deprecated
  color
  iconText
  companyID
  test
  firstName
  lastName
  email
  phone
  companyName
  address {
    address
    zipcode
    city
    country
  }
  note
  fidelityCardNumber
  account {
    id
    companyID
    updatedAt
    createdAt
    deprecated
    balance
    movements {
      items {
        ${customerMovement}
      }
      nextToken
    }
  }
  customFields {
    items {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      customerID
      customFieldID
      value
      customField {
        ${customField}
      }
    }
  }
`;

export const customerWithStats = `
  createdAt
  stats {
    nbSales
    average {
      total
      totalTax
      totalTaxFree
    }
    index
    totals {
      total
      totalTax
      totalTaxFree
    }
    totalDiscount
  }
`;

export const customerCustomField = `
  id
  updatedAt
  createdAt
  deprecated
  companyID
  test
  customerID
  customFieldID
  customFieldKey
  value
`;

export const paymentList = `
  id
  state
  type
  date
  number
  companyID
  test
  cashbookID
  shopID
  sourceID
  sourceName
  saleID
  paymentMethodID
  paymentMethodName
  paymentMethodType
  sourcePaymentID
  customerMovementID
  amount
`;

export const paymentGet = `
  id
  state
  type
  date
  number
  companyID
  test
  cashbookID
  cashbook {
    ${cashbookGet}
  }
  customer {
    ${customerGet}
  }
  shopID
  sourceID
  sourceName
  saleID
  paymentMethodID
  paymentMethodName
  paymentMethodType
  sourcePaymentID
  customerMovementID
  amount
`;

export const paymentGetLight = `
  id
  state
  type
  date
  number
  companyID
  test
  cashbookID
  cashbook {
    id
    number
    state
  }
  shopID
  sourceID
  sourceName
  saleID
  paymentMethodID
  paymentMethodName
  paymentMethodType
  sourcePaymentID
  customerMovementID
  amount
`;

export const paymentMethod = `
  id
  updatedAt
  createdAt
  deprecated
  companyID
  test
  name
  color
  type
  changeType
  countOnCashbookOpeningClosing
  opensCashDrawer
  enabled
`;

export const saleList = `
  id
  updatedAt
  createdAt
  deprecated
  companyID
  test
  cashbookID
  shopID
  sourceID
  sourceName
  targetID
  number
  state
  refundStatus
  refundedStatus
  paymentStatus
  note
  openedAt
  closedAt
  pendingAt
  total
  totalDiscount
  totalTaxFree
  totalTax
  totalRest
  payments {
    state
    type
    date
    paymentMethodID
    paymentMethodName
    paymentMethodType
    sourcePaymentID
    customerMovementID
    cashbookID
    amount
    paymentID
  }
`;

export const saleGetLight = `
  id
  updatedAt
  createdAt
  deprecated
  companyID
  test
  cashbookID
  cashbook {
    id
    number
    state
  }
  shopID
  sourceID
  sourceName
  targetID
  refundSales {
    items {
      id
      number
      total
      lines {
        state
        operationType
        targetSaleLineIndex
      }
    }
  }
  number
  state
  refundStatus
  refundedStatus
  paymentStatus
  note
  openedAt
  closedAt
  pendingAt
  customer {
    id
    firstName
    lastName
  }
  sellerID
  seller {
    ${seller}
  }
  lines {
    details {
      __typename
      ...on SaleLineProductDetails {
        productID
        name
        variant {
          options {
            name
            value
          }
        }
        options {
          name
          value {
            name
          }
          total
        }
      }
      ...on SaleLinePackDetails {
        packID
        name
        packVariant {
          details {
            name
            variant {
              options {
                name
                value
              }
            }
            options {
              name
              value {
                name
              }
              total
            }
          }
          quantity
          additionalPrice
          basePrice
        }
      }
    }
    refundedQuantity
    quantity
    price
    discounts {
      rate
      type
      discountID
      discountName
      amount
    }
    note
    operationType
    state
    targetSaleLineIndex
    motive
    total
    totalSellPrice
    totalDiscount
    totalTaxFree
    totalTax
  }
  discounts {
    rate
    type
    discountID
    discountName
    amount
  }
  payments {
    state
    type
    date
    paymentMethodID
    paymentMethodName
    paymentMethodType
    sourcePaymentID
    customerMovementID
    cashbookID
    amount
    paymentID
  }
  total
  totalDiscount
  totalTaxFree
  totalTax
  totalRest
`;

export const saleGetInfos = `
  id
  updatedAt
  createdAt
  companyID
  cashbookID
  cashbook {
    id
    number
    state
  }
  number
  state
  total
  totalDiscount
  totalTaxFree
  totalTax
  totalRest
`;

export const saleGet = `
  id
  updatedAt
  createdAt
  deprecated
  companyID
  test
  cashbookID
  cashbook {
    ${cashbookGet}
  }
  shopID
  sourceID
  sourceName
  targetID
  refundSales {
    items {
      id
      number
      total
      lines {
        state
        operationType
        targetSaleLineIndex
      }
    }
  }
  number
  state
  refundStatus
  refundedStatus
  paymentStatus
  note
  openedAt
  closedAt
  pendingAt
  customer {
    ${customerGet}
  }
  sellerID
  seller {
    ${seller}
  }
  lines {
    details {
      __typename
      ...on SaleLineProductDetails {
        productID
        name
        variant {
          options {
            name
            value
          }
        }
        options {
          name
          value {
            name
          }
          total
        }
      }
      ...on SaleLinePackDetails {
        packID
        name
        packVariant {
          details {
            name
            variant {
              options {
                name
                value
              }
            }
            options {
              name
              value {
                name
              }
              total
            }
          }
          quantity
          additionalPrice
          basePrice
        }
      }
    }
    refundedQuantity
    quantity
    price
    discounts {
      rate
      type
      discountID
      discountName
      amount
    }
    note
    operationType
    state
    targetSaleLineIndex
    motive
    total
    totalSellPrice
    totalDiscount
    totalTaxFree
    totalTax
  }
  discounts {
    rate
    type
    discountID
    discountName
    amount
  }
  payments {
    state
    type
    date
    paymentMethodID
    paymentMethodName
    paymentMethodType
    sourcePaymentID
    customerMovementID
    cashbookID
    amount
    paymentID
  }
  total
  totalDiscount
  totalTaxFree
  totalTax
  totalRest
`;

export const salekWithStats = `
  ${saleGet}
  stats {
    margin
    taxes {
      id
      totals {
        total
        totalTax
        totalTaxFree
      }
    }
  }
`;

export const saleWithStatsLight = `
  ${saleGetLight}
  stats {
    margin
    taxes {
      id
      totals {
        total
        totalTax
        totalTaxFree
      }
    }
  }
`;

export const account = `
  id
  updatedAt
  deprecated
  firstName
  lastName
  intercomHMAC
  email
  phone
  color
  companies {
    items {
      id
      updatedAt
      createdAt
      deprecated
      role
      accountID
      companyID
      company {
        ${company}
      }
    }
    nextToken
  }
  shops {
    items {
      id
      updatedAt
      createdAt
      deprecated
      accountID
      companyID
      shopID
      shop {
        ${shop}
      }
    }
    nextToken
  }
`;

export const sellerCode = `
  id
  updatedAt
  createdAt
  deprecated
  accountID
  companyID
  code
`;

export const user = `
  id
  updatedAt
  createdAt
  deprecated
  firstName
  lastName
  color
  iconText
  email
  company {
    id
    updatedAt
    createdAt
    deprecated
    accountID
    companyID
    owner
    role
    company {
      ${company}
    }
  }
  shops {
    shopID
  }
`;

export const invitation = `
  id
  updatedAt
  createdAt
  deprecated
  companyID
  companyName
  email
  role
  shops
  expiresAt
  status
  senderID
  senderName
  senderEmail
`;

export const slot = `
  id
  updatedAt
  createdAt
  deprecated
  companyID
  shopID
  name
  status
`;

export const companySettings = `
  id
  updatedAt
  createdAt
  companyID
  accountID
  key
  value
`;

export const backgroundJob = `
  id
  updatedAt
  createdAt
  deprecated
  companyID
  test
  shopID
  sourceEmail
  type
  state
  details {
    ... on CatalogMigrationDetails {
      PRODUCT {
        total
        successCount
        errorCount
      }
      PRODUCT_VARIANT {
        total
        successCount
        errorCount
      }
      CATEGORY {
        total
        successCount
        errorCount
      }
      TAX {
        total
        successCount
        errorCount
      }
      DISCOUNT {
        total
        successCount
        errorCount
      }
    }
    ... on CustomerMigrationDetails {
      CUSTOMER {
        total
        successCount
        errorCount
      }
    }
    ... on InventoryMigrationDetails {
      INVENTORY {
        total
        successCount
        errorCount
      }
    }
    ... on ImportDetails {
      IMPORT {
        total
        successCount
        errorCount
      }
    }
  }
  message
  reportUrl
  flatfileBatchId
  flatfileSheetId
`;
