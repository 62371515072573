/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCompany = /* GraphQL */ `
  subscription OnCreateCompany($companyID: ID!) {
    onCreateCompany(companyID: $companyID) {
      id
      updatedAt
      createdAt
      deprecated
      name
      address
      country
      currency
      currencyDecimals
      locale
      vat
    }
  }
`;
export const onUpdateCompany = /* GraphQL */ `
  subscription OnUpdateCompany($companyID: ID!) {
    onUpdateCompany(companyID: $companyID) {
      id
      updatedAt
      createdAt
      deprecated
      name
      address
      country
      currency
      currencyDecimals
      locale
      vat
    }
  }
`;
export const onDeleteCompany = /* GraphQL */ `
  subscription OnDeleteCompany($companyID: ID!) {
    onDeleteCompany(companyID: $companyID) {
      company {
        id
        updatedAt
        createdAt
        deprecated
        name
        address
        country
        currency
        currencyDecimals
        locale
        vat
      }
      catalog {
        updatedAt
        createdAt
        deprecated
        companyID
        company {
          id
          updatedAt
          createdAt
          deprecated
          name
          address
          country
          currency
          currencyDecimals
          locale
          vat
        }
        id
      }
      shops {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        company {
          id
          updatedAt
          createdAt
          deprecated
          name
          address
          country
          currency
          currencyDecimals
          locale
          vat
        }
        name
        address {
          address
          zipcode
          city
          country
        }
        siret
        vat
        phone
        footnote
        logo
        websiteURL
        brandName
        email
      }
      companyAccounts {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        company {
          id
          updatedAt
          createdAt
          deprecated
          name
          address
          country
          currency
          currencyDecimals
          locale
          vat
        }
        owner
        role
        read
        update
        billing
        billingExport
        shopWrite
        deviceSlotWrite
        deviceSlotUse
        paymentMethodRead
        paymentMethodCreate
        paymentMethodUpdate
        paymentMethodDelete
        customerRead
        customerCreate
        customerUpdate
        customerDelete
        customerExport
        inventoryRead
        cashbookRead
        saleRead
        paymentRead
        userInvite
        userRead
        userUpdate
        userRemove
        sell
        sync
        id
      }
      shopAccounts {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        shopID
        shop {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          name
          address {
            address
            zipcode
            city
            country
          }
          siret
          vat
          phone
          footnote
          logo
          websiteURL
          brandName
          email
        }
        read
        update
        delete
        inventoryRead
        inventoryWrite
        cashbookRead
        cashbookWrite
        saleRead
        saleWrite
        paymentRead
        paymentWrite
        userInvite
        userRead
        userUpdate
        userRemove
        activitiesExport
        tillCashbooksRead
        tillSalesSearch
        tillSaleSell
        tillSaleRefund
        tillSaleReturn
        tillCashbookOpen
        tillCashbookClose
        eventCreate
        sync
        id
      }
      catalogAccounts {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        catalogID
        catalog {
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          id
        }
        read
        create
        update
        delete
        sync
        id
      }
    }
  }
`;
export const onCreateShop = /* GraphQL */ `
  subscription OnCreateShop($companyID: ID!) {
    onCreateShop(companyID: $companyID) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      company {
        id
        updatedAt
        createdAt
        deprecated
        name
        address
        country
        currency
        currencyDecimals
        locale
        vat
      }
      name
      address {
        address
        zipcode
        city
        country
      }
      siret
      vat
      phone
      footnote
      logo
      websiteURL
      brandName
      email
    }
  }
`;
export const onUpdateShop = /* GraphQL */ `
  subscription OnUpdateShop($companyID: ID!) {
    onUpdateShop(companyID: $companyID) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      company {
        id
        updatedAt
        createdAt
        deprecated
        name
        address
        country
        currency
        currencyDecimals
        locale
        vat
      }
      name
      address {
        address
        zipcode
        city
        country
      }
      siret
      vat
      phone
      footnote
      logo
      websiteURL
      brandName
      email
    }
  }
`;
export const onDeleteShop = /* GraphQL */ `
  subscription OnDeleteShop($companyID: ID!) {
    onDeleteShop(companyID: $companyID) {
      shop {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        company {
          id
          updatedAt
          createdAt
          deprecated
          name
          address
          country
          currency
          currencyDecimals
          locale
          vat
        }
        name
        address {
          address
          zipcode
          city
          country
        }
        siret
        vat
        phone
        footnote
        logo
        websiteURL
        brandName
        email
      }
      shopAccounts {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        shopID
        shop {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          name
          address {
            address
            zipcode
            city
            country
          }
          siret
          vat
          phone
          footnote
          logo
          websiteURL
          brandName
          email
        }
        read
        update
        delete
        inventoryRead
        inventoryWrite
        cashbookRead
        cashbookWrite
        saleRead
        saleWrite
        paymentRead
        paymentWrite
        userInvite
        userRead
        userUpdate
        userRemove
        activitiesExport
        tillCashbooksRead
        tillSalesSearch
        tillSaleSell
        tillSaleRefund
        tillSaleReturn
        tillCashbookOpen
        tillCashbookClose
        eventCreate
        sync
        id
      }
    }
  }
`;
export const onCreateCompanySetting = /* GraphQL */ `
  subscription OnCreateCompanySetting($companyID: ID!) {
    onCreateCompanySetting(companyID: $companyID) {
      id
      updatedAt
      createdAt
      companyID
      accountID
      key
      value
    }
  }
`;
export const onUpdateCompanySetting = /* GraphQL */ `
  subscription OnUpdateCompanySetting($companyID: ID!) {
    onUpdateCompanySetting(companyID: $companyID) {
      id
      updatedAt
      createdAt
      companyID
      accountID
      key
      value
    }
  }
`;
export const onDeleteCompanySetting = /* GraphQL */ `
  subscription OnDeleteCompanySetting($companyID: ID!) {
    onDeleteCompanySetting(companyID: $companyID) {
      id
      updatedAt
      createdAt
      companyID
      accountID
      key
      value
    }
  }
`;
export const onChangeCompanySetting = /* GraphQL */ `
  subscription OnChangeCompanySetting($companyID: ID!) {
    onChangeCompanySetting(companyID: $companyID) {
      id
      updatedAt
      createdAt
      companyID
      accountID
      key
      value
    }
  }
`;
export const onCreateSlot = /* GraphQL */ `
  subscription OnCreateSlot($shopID: ID!) {
    onCreateSlot(shopID: $shopID) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      name
      status
    }
  }
`;
export const onUpdateSlot = /* GraphQL */ `
  subscription OnUpdateSlot($shopID: ID!) {
    onUpdateSlot(shopID: $shopID) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      name
      status
    }
  }
`;
export const onChangeSlot = /* GraphQL */ `
  subscription OnChangeSlot($shopID: ID!) {
    onChangeSlot(shopID: $shopID) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      name
      status
    }
  }
`;
export const onCreateSellerCode = /* GraphQL */ `
  subscription OnCreateSellerCode($companyID: ID!) {
    onCreateSellerCode(companyID: $companyID) {
      updatedAt
      createdAt
      deprecated
      accountID
      companyID
      company {
        id
        updatedAt
        createdAt
        deprecated
        name
        address
        country
        currency
        currencyDecimals
        locale
        vat
      }
      code
      id
    }
  }
`;
export const onUpdateSellerCode = /* GraphQL */ `
  subscription OnUpdateSellerCode($companyID: ID!) {
    onUpdateSellerCode(companyID: $companyID) {
      updatedAt
      createdAt
      deprecated
      accountID
      companyID
      company {
        id
        updatedAt
        createdAt
        deprecated
        name
        address
        country
        currency
        currencyDecimals
        locale
        vat
      }
      code
      id
    }
  }
`;
export const onDeleteSellerCode = /* GraphQL */ `
  subscription OnDeleteSellerCode($companyID: ID!) {
    onDeleteSellerCode(companyID: $companyID) {
      updatedAt
      createdAt
      deprecated
      accountID
      companyID
      company {
        id
        updatedAt
        createdAt
        deprecated
        name
        address
        country
        currency
        currencyDecimals
        locale
        vat
      }
      code
      id
    }
  }
`;
export const onChangeSellerCode = /* GraphQL */ `
  subscription OnChangeSellerCode($companyID: ID!) {
    onChangeSellerCode(companyID: $companyID) {
      updatedAt
      createdAt
      deprecated
      accountID
      companyID
      company {
        id
        updatedAt
        createdAt
        deprecated
        name
        address
        country
        currency
        currencyDecimals
        locale
        vat
      }
      code
      id
    }
  }
`;
export const onUpdateGridCell = /* GraphQL */ `
  subscription OnUpdateGridCell(
    $catalogID: ID!
    $deviceID: ID
    $test: Boolean!
  ) {
    onUpdateGridCell(catalogID: $catalogID, deviceID: $deviceID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      compositeID
      catalogID
      test
      deviceID
      page
      column
      row
      type
      objectID
    }
  }
`;
export const onDeleteGridCell = /* GraphQL */ `
  subscription OnDeleteGridCell(
    $catalogID: ID!
    $deviceID: ID
    $test: Boolean!
  ) {
    onDeleteGridCell(catalogID: $catalogID, deviceID: $deviceID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      compositeID
      catalogID
      test
      deviceID
      page
      column
      row
      type
      objectID
    }
  }
`;
export const onChangeGridCell = /* GraphQL */ `
  subscription OnChangeGridCell($catalogID: ID!, $test: Boolean!) {
    onChangeGridCell(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      compositeID
      catalogID
      test
      deviceID
      page
      column
      row
      type
      objectID
    }
  }
`;
export const onCreateProduct = /* GraphQL */ `
  subscription OnCreateProduct($catalogID: ID!, $test: Boolean!) {
    onCreateProduct(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      categoryID
      category {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            categoryID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          quantity
          histogram {
            id
          }
        }
      }
      taxID
      tax {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        rate
      }
      bulkType
      buyPrice
      sellPrice
      barcode
      reference
      variations {
        name
        options
      }
      variantsCount
      variants {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          id
          productID
          variantID
          options {
            name
            value
          }
          order
          taxID
          tax {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            rate
          }
          buyPrice
          sellPrice
          barcode
          reference
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
          stats {
            margin
            quantity
          }
        }
        nextToken
      }
      options {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          optionID
          option {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            name
          }
          id
        }
        nextToken
      }
      tags {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          tagID
          tag {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            name
            createdByID
          }
          id
        }
        nextToken
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      inventoryQuantities {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        productID
        variantID
        quantity
        resetDate
        shop {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          name
          address {
            address
            zipcode
            city
            country
          }
          siret
          vat
          phone
          footnote
          logo
          websiteURL
          brandName
          email
        }
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        margin
        quantity
        histogram {
          id
        }
      }
      inventoryStats {
        content {
          quantity
          value
        }
        byShop {
          id
          content {
            quantity
            value
          }
        }
      }
    }
  }
`;
export const onUpdateProduct = /* GraphQL */ `
  subscription OnUpdateProduct($catalogID: ID!, $test: Boolean!) {
    onUpdateProduct(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      categoryID
      category {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            categoryID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          quantity
          histogram {
            id
          }
        }
      }
      taxID
      tax {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        rate
      }
      bulkType
      buyPrice
      sellPrice
      barcode
      reference
      variations {
        name
        options
      }
      variantsCount
      variants {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          id
          productID
          variantID
          options {
            name
            value
          }
          order
          taxID
          tax {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            rate
          }
          buyPrice
          sellPrice
          barcode
          reference
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
          stats {
            margin
            quantity
          }
        }
        nextToken
      }
      options {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          optionID
          option {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            name
          }
          id
        }
        nextToken
      }
      tags {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          tagID
          tag {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            name
            createdByID
          }
          id
        }
        nextToken
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      inventoryQuantities {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        productID
        variantID
        quantity
        resetDate
        shop {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          name
          address {
            address
            zipcode
            city
            country
          }
          siret
          vat
          phone
          footnote
          logo
          websiteURL
          brandName
          email
        }
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        margin
        quantity
        histogram {
          id
        }
      }
      inventoryStats {
        content {
          quantity
          value
        }
        byShop {
          id
          content {
            quantity
            value
          }
        }
      }
    }
  }
`;
export const onDeleteProduct = /* GraphQL */ `
  subscription OnDeleteProduct($catalogID: ID!, $test: Boolean!) {
    onDeleteProduct(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      categoryID
      category {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            categoryID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          quantity
          histogram {
            id
          }
        }
      }
      taxID
      tax {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        rate
      }
      bulkType
      buyPrice
      sellPrice
      barcode
      reference
      variations {
        name
        options
      }
      variantsCount
      variants {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          id
          productID
          variantID
          options {
            name
            value
          }
          order
          taxID
          tax {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            rate
          }
          buyPrice
          sellPrice
          barcode
          reference
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
          stats {
            margin
            quantity
          }
        }
        nextToken
      }
      options {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          optionID
          option {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            name
          }
          id
        }
        nextToken
      }
      tags {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          tagID
          tag {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            name
            createdByID
          }
          id
        }
        nextToken
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      inventoryQuantities {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        productID
        variantID
        quantity
        resetDate
        shop {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          name
          address {
            address
            zipcode
            city
            country
          }
          siret
          vat
          phone
          footnote
          logo
          websiteURL
          brandName
          email
        }
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        margin
        quantity
        histogram {
          id
        }
      }
      inventoryStats {
        content {
          quantity
          value
        }
        byShop {
          id
          content {
            quantity
            value
          }
        }
      }
    }
  }
`;
export const onChangeProduct = /* GraphQL */ `
  subscription OnChangeProduct($catalogID: ID!, $test: Boolean!) {
    onChangeProduct(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      categoryID
      category {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            categoryID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          quantity
          histogram {
            id
          }
        }
      }
      taxID
      tax {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        rate
      }
      bulkType
      buyPrice
      sellPrice
      barcode
      reference
      variations {
        name
        options
      }
      variantsCount
      variants {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          id
          productID
          variantID
          options {
            name
            value
          }
          order
          taxID
          tax {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            rate
          }
          buyPrice
          sellPrice
          barcode
          reference
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
          stats {
            margin
            quantity
          }
        }
        nextToken
      }
      options {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          optionID
          option {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            name
          }
          id
        }
        nextToken
      }
      tags {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          tagID
          tag {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            name
            createdByID
          }
          id
        }
        nextToken
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          productID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      inventoryQuantities {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        productID
        variantID
        quantity
        resetDate
        shop {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          name
          address {
            address
            zipcode
            city
            country
          }
          siret
          vat
          phone
          footnote
          logo
          websiteURL
          brandName
          email
        }
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        margin
        quantity
        histogram {
          id
        }
      }
      inventoryStats {
        content {
          quantity
          value
        }
        byShop {
          id
          content {
            quantity
            value
          }
        }
      }
    }
  }
`;
export const onUpdateProductVariants = /* GraphQL */ `
  subscription OnUpdateProductVariants($catalogID: ID!, $test: Boolean!) {
    onUpdateProductVariants(catalogID: $catalogID, test: $test) {
      catalogID
      test
      productID
      product {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        color
        iconText
        photo
        categoryID
        category {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          name
          color
          iconText
          photo
          customFields {
            nextToken
          }
          stats {
            quantity
          }
        }
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        bulkType
        buyPrice
        sellPrice
        barcode
        reference
        variations {
          name
          options
        }
        variantsCount
        variants {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            id
            productID
            variantID
            order
            taxID
            buyPrice
            sellPrice
            barcode
            reference
          }
          nextToken
        }
        options {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            optionID
            id
          }
          nextToken
        }
        tags {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            tagID
            id
          }
          nextToken
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            productID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      variations {
        name
        options
      }
      insertions {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        order
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      modifications {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        order
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
      deletions {
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        id
        productID
        variantID
        options {
          name
          value
        }
        order
        taxID
        tax {
          id
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          rate
        }
        buyPrice
        sellPrice
        barcode
        reference
        inventoryQuantities {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          productID
          variantID
          quantity
          resetDate
          shop {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            name
            siret
            vat
            phone
            footnote
            logo
            websiteURL
            brandName
            email
          }
        }
        stats {
          margin
          quantity
          histogram {
            id
          }
        }
        inventoryStats {
          content {
            quantity
            value
          }
          byShop {
            id
          }
        }
      }
    }
  }
`;
export const onCreateProductOption = /* GraphQL */ `
  subscription OnCreateProductOption($catalogID: ID!, $test: Boolean!) {
    onCreateProductOption(catalogID: $catalogID, test: $test) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      productID
      optionID
      option {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        values {
          name
          price
        }
        stats {
          quantity
          histogram {
            id
          }
        }
      }
      id
    }
  }
`;
export const onDeleteProductOption = /* GraphQL */ `
  subscription OnDeleteProductOption($catalogID: ID!, $test: Boolean!) {
    onDeleteProductOption(catalogID: $catalogID, test: $test) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      productID
      optionID
      option {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        values {
          name
          price
        }
        stats {
          quantity
          histogram {
            id
          }
        }
      }
      id
    }
  }
`;
export const onChangeProductOption = /* GraphQL */ `
  subscription OnChangeProductOption($catalogID: ID!, $test: Boolean!) {
    onChangeProductOption(catalogID: $catalogID, test: $test) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      productID
      optionID
      option {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        values {
          name
          price
        }
        stats {
          quantity
          histogram {
            id
          }
        }
      }
      id
    }
  }
`;
export const onCreateProductTag = /* GraphQL */ `
  subscription OnCreateProductTag($catalogID: ID!, $test: Boolean!) {
    onCreateProductTag(catalogID: $catalogID, test: $test) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      productID
      tagID
      tag {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        createdByID
      }
      id
    }
  }
`;
export const onDeleteProductTag = /* GraphQL */ `
  subscription OnDeleteProductTag($catalogID: ID!, $test: Boolean!) {
    onDeleteProductTag(catalogID: $catalogID, test: $test) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      productID
      tagID
      tag {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        createdByID
      }
      id
    }
  }
`;
export const onChangeProductTag = /* GraphQL */ `
  subscription OnChangeProductTag($catalogID: ID!, $test: Boolean!) {
    onChangeProductTag(catalogID: $catalogID, test: $test) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      productID
      tagID
      tag {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        name
        createdByID
      }
      id
    }
  }
`;
export const onCreateProductCustomField = /* GraphQL */ `
  subscription OnCreateProductCustomField($catalogID: ID!, $test: Boolean!) {
    onCreateProductCustomField(catalogID: $catalogID, test: $test) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      productID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const onUpdateProductCustomField = /* GraphQL */ `
  subscription OnUpdateProductCustomField($catalogID: ID!, $test: Boolean!) {
    onUpdateProductCustomField(catalogID: $catalogID, test: $test) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      productID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const onDeleteProductCustomField = /* GraphQL */ `
  subscription OnDeleteProductCustomField($catalogID: ID!, $test: Boolean!) {
    onDeleteProductCustomField(catalogID: $catalogID, test: $test) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      productID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const onChangeProductCustomField = /* GraphQL */ `
  subscription OnChangeProductCustomField($catalogID: ID!, $test: Boolean!) {
    onChangeProductCustomField(catalogID: $catalogID, test: $test) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      productID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const onCreateCategory = /* GraphQL */ `
  subscription OnCreateCategory($catalogID: ID!, $test: Boolean!) {
    onCreateCategory(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          categoryID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const onUpdateCategory = /* GraphQL */ `
  subscription OnUpdateCategory($catalogID: ID!, $test: Boolean!) {
    onUpdateCategory(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          categoryID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const onDeleteCategory = /* GraphQL */ `
  subscription OnDeleteCategory($catalogID: ID!, $test: Boolean!) {
    onDeleteCategory(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          categoryID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const onChangeCategory = /* GraphQL */ `
  subscription OnChangeCategory($catalogID: ID!, $test: Boolean!) {
    onChangeCategory(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          categoryID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const onCreateCategoryCustomField = /* GraphQL */ `
  subscription OnCreateCategoryCustomField($catalogID: ID!, $test: Boolean!) {
    onCreateCategoryCustomField(catalogID: $catalogID, test: $test) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      categoryID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const onUpdateCategoryCustomField = /* GraphQL */ `
  subscription OnUpdateCategoryCustomField($catalogID: ID!, $test: Boolean!) {
    onUpdateCategoryCustomField(catalogID: $catalogID, test: $test) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      categoryID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const onDeleteCategoryCustomField = /* GraphQL */ `
  subscription OnDeleteCategoryCustomField($catalogID: ID!, $test: Boolean!) {
    onDeleteCategoryCustomField(catalogID: $catalogID, test: $test) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      categoryID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const onChangeCategoryCustomField = /* GraphQL */ `
  subscription OnChangeCategoryCustomField($catalogID: ID!, $test: Boolean!) {
    onChangeCategoryCustomField(catalogID: $catalogID, test: $test) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      categoryID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const onCreateCustomField = /* GraphQL */ `
  subscription OnCreateCustomField($catalogID: ID!, $test: Boolean!) {
    onCreateCustomField(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      key
      objectType
      valueType
      name
      createdByID
    }
  }
`;
export const onUpdateCustomField = /* GraphQL */ `
  subscription OnUpdateCustomField($catalogID: ID!, $test: Boolean!) {
    onUpdateCustomField(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      key
      objectType
      valueType
      name
      createdByID
    }
  }
`;
export const onDeleteCustomField = /* GraphQL */ `
  subscription OnDeleteCustomField($catalogID: ID!, $test: Boolean!) {
    onDeleteCustomField(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      key
      objectType
      valueType
      name
      createdByID
    }
  }
`;
export const onChangeCustomField = /* GraphQL */ `
  subscription OnChangeCustomField($catalogID: ID!, $test: Boolean!) {
    onChangeCustomField(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      key
      objectType
      valueType
      name
      createdByID
    }
  }
`;
export const onCreateDiscount = /* GraphQL */ `
  subscription OnCreateDiscount($catalogID: ID!, $test: Boolean!) {
    onCreateDiscount(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      photo
      type
      rate
      stats {
        amount
        quantity
        histogram {
          id
          amount
        }
      }
    }
  }
`;
export const onUpdateDiscount = /* GraphQL */ `
  subscription OnUpdateDiscount($catalogID: ID!, $test: Boolean!) {
    onUpdateDiscount(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      photo
      type
      rate
      stats {
        amount
        quantity
        histogram {
          id
          amount
        }
      }
    }
  }
`;
export const onDeleteDiscount = /* GraphQL */ `
  subscription OnDeleteDiscount($catalogID: ID!, $test: Boolean!) {
    onDeleteDiscount(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      photo
      type
      rate
      stats {
        amount
        quantity
        histogram {
          id
          amount
        }
      }
    }
  }
`;
export const onChangeDiscount = /* GraphQL */ `
  subscription OnChangeDiscount($catalogID: ID!, $test: Boolean!) {
    onChangeDiscount(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      photo
      type
      rate
      stats {
        amount
        quantity
        histogram {
          id
          amount
        }
      }
    }
  }
`;
export const onCreateOption = /* GraphQL */ `
  subscription OnCreateOption($catalogID: ID!, $test: Boolean!) {
    onCreateOption(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      values {
        name
        price
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const onUpdateOption = /* GraphQL */ `
  subscription OnUpdateOption($catalogID: ID!, $test: Boolean!) {
    onUpdateOption(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      values {
        name
        price
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const onDeleteOption = /* GraphQL */ `
  subscription OnDeleteOption($catalogID: ID!, $test: Boolean!) {
    onDeleteOption(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      values {
        name
        price
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const onChangeOption = /* GraphQL */ `
  subscription OnChangeOption($catalogID: ID!, $test: Boolean!) {
    onChangeOption(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      values {
        name
        price
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const onCreatePack = /* GraphQL */ `
  subscription OnCreatePack($catalogID: ID!, $test: Boolean!) {
    onCreatePack(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      barcode
      reference
      sellPrice
      packVariations {
        options {
          productID
          quantity
          additionalPrice
        }
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          packID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const onUpdatePack = /* GraphQL */ `
  subscription OnUpdatePack($catalogID: ID!, $test: Boolean!) {
    onUpdatePack(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      barcode
      reference
      sellPrice
      packVariations {
        options {
          productID
          quantity
          additionalPrice
        }
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          packID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const onDeletePack = /* GraphQL */ `
  subscription OnDeletePack($catalogID: ID!, $test: Boolean!) {
    onDeletePack(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      barcode
      reference
      sellPrice
      packVariations {
        options {
          productID
          quantity
          additionalPrice
        }
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          packID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const onChangePack = /* GraphQL */ `
  subscription OnChangePack($catalogID: ID!, $test: Boolean!) {
    onChangePack(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      color
      iconText
      photo
      barcode
      reference
      sellPrice
      packVariations {
        options {
          productID
          quantity
          additionalPrice
        }
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          catalogID
          test
          packID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        quantity
        histogram {
          id
        }
      }
    }
  }
`;
export const onCreatePackCustomField = /* GraphQL */ `
  subscription OnCreatePackCustomField($catalogID: ID!, $test: Boolean!) {
    onCreatePackCustomField(catalogID: $catalogID, test: $test) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      packID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const onUpdatePackCustomField = /* GraphQL */ `
  subscription OnUpdatePackCustomField($catalogID: ID!, $test: Boolean!) {
    onUpdatePackCustomField(catalogID: $catalogID, test: $test) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      packID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const onDeletePackCustomField = /* GraphQL */ `
  subscription OnDeletePackCustomField($catalogID: ID!, $test: Boolean!) {
    onDeletePackCustomField(catalogID: $catalogID, test: $test) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      packID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const onChangePackCustomField = /* GraphQL */ `
  subscription OnChangePackCustomField($catalogID: ID!, $test: Boolean!) {
    onChangePackCustomField(catalogID: $catalogID, test: $test) {
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      packID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const onCreateTag = /* GraphQL */ `
  subscription OnCreateTag($catalogID: ID!, $test: Boolean!) {
    onCreateTag(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      createdByID
    }
  }
`;
export const onUpdateTag = /* GraphQL */ `
  subscription OnUpdateTag($catalogID: ID!, $test: Boolean!) {
    onUpdateTag(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      createdByID
    }
  }
`;
export const onDeleteTag = /* GraphQL */ `
  subscription OnDeleteTag($catalogID: ID!, $test: Boolean!) {
    onDeleteTag(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      createdByID
    }
  }
`;
export const onChangeTag = /* GraphQL */ `
  subscription OnChangeTag($catalogID: ID!, $test: Boolean!) {
    onChangeTag(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      name
      createdByID
    }
  }
`;
export const onCreateTax = /* GraphQL */ `
  subscription OnCreateTax($catalogID: ID!, $test: Boolean!) {
    onCreateTax(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      rate
    }
  }
`;
export const onUpdateTax = /* GraphQL */ `
  subscription OnUpdateTax($catalogID: ID!, $test: Boolean!) {
    onUpdateTax(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      rate
    }
  }
`;
export const onDeleteTax = /* GraphQL */ `
  subscription OnDeleteTax($catalogID: ID!, $test: Boolean!) {
    onDeleteTax(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      rate
    }
  }
`;
export const onChangeTax = /* GraphQL */ `
  subscription OnChangeTax($catalogID: ID!, $test: Boolean!) {
    onChangeTax(catalogID: $catalogID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      catalogID
      test
      rate
    }
  }
`;
export const onCreateInventoryMovement = /* GraphQL */ `
  subscription OnCreateInventoryMovement($companyID: ID!, $test: Boolean!) {
    onCreateInventoryMovement(companyID: $companyID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      state
      stateDate
      motive
      origin
      originID
      destination
      destinationID
      totalQuantity
      movements {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          variantID
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      products {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          details {
            name
            sellPrice
            buyPrice
            bulkType
            barcode
            reference
            categoryID
            taxID
          }
          hasVariants
          variants {
            nextToken
          }
          variantsCount
          variantsSelectedAll
          variantsAll {
            nextToken
          }
          variantsCountAll
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      history {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          state
          date
          motive
        }
        nextToken
      }
    }
  }
`;
export const onCreateOriginInventoryMovement = /* GraphQL */ `
  subscription OnCreateOriginInventoryMovement(
    $originID: ID!
    $test: Boolean!
  ) {
    onCreateOriginInventoryMovement(originID: $originID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      state
      stateDate
      motive
      origin
      originID
      destination
      destinationID
      totalQuantity
      movements {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          variantID
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      products {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          details {
            name
            sellPrice
            buyPrice
            bulkType
            barcode
            reference
            categoryID
            taxID
          }
          hasVariants
          variants {
            nextToken
          }
          variantsCount
          variantsSelectedAll
          variantsAll {
            nextToken
          }
          variantsCountAll
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      history {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          state
          date
          motive
        }
        nextToken
      }
    }
  }
`;
export const onCreateDestinationInventoryMovement = /* GraphQL */ `
  subscription OnCreateDestinationInventoryMovement(
    $destinationID: ID!
    $test: Boolean!
  ) {
    onCreateDestinationInventoryMovement(
      destinationID: $destinationID
      test: $test
    ) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      state
      stateDate
      motive
      origin
      originID
      destination
      destinationID
      totalQuantity
      movements {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          variantID
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      products {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          details {
            name
            sellPrice
            buyPrice
            bulkType
            barcode
            reference
            categoryID
            taxID
          }
          hasVariants
          variants {
            nextToken
          }
          variantsCount
          variantsSelectedAll
          variantsAll {
            nextToken
          }
          variantsCountAll
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      history {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          state
          date
          motive
        }
        nextToken
      }
    }
  }
`;
export const onUpdateInventoryMovement = /* GraphQL */ `
  subscription OnUpdateInventoryMovement($companyID: ID!, $test: Boolean!) {
    onUpdateInventoryMovement(companyID: $companyID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      state
      stateDate
      motive
      origin
      originID
      destination
      destinationID
      totalQuantity
      movements {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          variantID
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      products {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          details {
            name
            sellPrice
            buyPrice
            bulkType
            barcode
            reference
            categoryID
            taxID
          }
          hasVariants
          variants {
            nextToken
          }
          variantsCount
          variantsSelectedAll
          variantsAll {
            nextToken
          }
          variantsCountAll
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      history {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          state
          date
          motive
        }
        nextToken
      }
    }
  }
`;
export const onUpdateOriginInventoryMovement = /* GraphQL */ `
  subscription OnUpdateOriginInventoryMovement(
    $originID: ID!
    $test: Boolean!
  ) {
    onUpdateOriginInventoryMovement(originID: $originID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      state
      stateDate
      motive
      origin
      originID
      destination
      destinationID
      totalQuantity
      movements {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          variantID
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      products {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          details {
            name
            sellPrice
            buyPrice
            bulkType
            barcode
            reference
            categoryID
            taxID
          }
          hasVariants
          variants {
            nextToken
          }
          variantsCount
          variantsSelectedAll
          variantsAll {
            nextToken
          }
          variantsCountAll
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      history {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          state
          date
          motive
        }
        nextToken
      }
    }
  }
`;
export const onUpdateDestinationInventoryMovement = /* GraphQL */ `
  subscription OnUpdateDestinationInventoryMovement(
    $destinationID: ID!
    $test: Boolean!
  ) {
    onUpdateDestinationInventoryMovement(
      destinationID: $destinationID
      test: $test
    ) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      state
      stateDate
      motive
      origin
      originID
      destination
      destinationID
      totalQuantity
      movements {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          variantID
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      products {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          details {
            name
            sellPrice
            buyPrice
            bulkType
            barcode
            reference
            categoryID
            taxID
          }
          hasVariants
          variants {
            nextToken
          }
          variantsCount
          variantsSelectedAll
          variantsAll {
            nextToken
          }
          variantsCountAll
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      history {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          state
          date
          motive
        }
        nextToken
      }
    }
  }
`;
export const onCancelInventoryMovement = /* GraphQL */ `
  subscription OnCancelInventoryMovement($companyID: ID!, $test: Boolean!) {
    onCancelInventoryMovement(companyID: $companyID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      state
      stateDate
      motive
      origin
      originID
      destination
      destinationID
      totalQuantity
      movements {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          variantID
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      products {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          details {
            name
            sellPrice
            buyPrice
            bulkType
            barcode
            reference
            categoryID
            taxID
          }
          hasVariants
          variants {
            nextToken
          }
          variantsCount
          variantsSelectedAll
          variantsAll {
            nextToken
          }
          variantsCountAll
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      history {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          state
          date
          motive
        }
        nextToken
      }
    }
  }
`;
export const onCancelOriginInventoryMovement = /* GraphQL */ `
  subscription OnCancelOriginInventoryMovement(
    $originID: ID!
    $test: Boolean!
  ) {
    onCancelOriginInventoryMovement(originID: $originID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      state
      stateDate
      motive
      origin
      originID
      destination
      destinationID
      totalQuantity
      movements {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          variantID
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      products {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          details {
            name
            sellPrice
            buyPrice
            bulkType
            barcode
            reference
            categoryID
            taxID
          }
          hasVariants
          variants {
            nextToken
          }
          variantsCount
          variantsSelectedAll
          variantsAll {
            nextToken
          }
          variantsCountAll
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      history {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          state
          date
          motive
        }
        nextToken
      }
    }
  }
`;
export const onCancelDestinationInventoryMovement = /* GraphQL */ `
  subscription OnCancelDestinationInventoryMovement(
    $destinationID: ID!
    $test: Boolean!
  ) {
    onCancelDestinationInventoryMovement(
      destinationID: $destinationID
      test: $test
    ) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      state
      stateDate
      motive
      origin
      originID
      destination
      destinationID
      totalQuantity
      movements {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          variantID
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      products {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          productID
          details {
            name
            sellPrice
            buyPrice
            bulkType
            barcode
            reference
            categoryID
            taxID
          }
          hasVariants
          variants {
            nextToken
          }
          variantsCount
          variantsSelectedAll
          variantsAll {
            nextToken
          }
          variantsCountAll
          quantity
          actualQuantity
          price
          inventoryQuantities {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            productID
            variantID
            quantity
            resetDate
          }
        }
        nextToken
      }
      history {
        items {
          id
          updatedAt
          createdAt
          deprecated
          inventoryMovementID
          state
          date
          motive
        }
        nextToken
      }
    }
  }
`;
export const onResetProductInventory = /* GraphQL */ `
  subscription OnResetProductInventory($shopID: ID!, $test: Boolean!) {
    onResetProductInventory(shopID: $shopID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      productID
      variantID
      salablePK
      shopSalablePK
      date
      quantity
      buyPrice
    }
  }
`;
export const onResetInventory = /* GraphQL */ `
  subscription OnResetInventory($shopID: ID!, $test: Boolean!) {
    onResetInventory(shopID: $shopID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      date
    }
  }
`;
export const onUpdateProductInventoryQuantityAny = /* GraphQL */ `
  subscription OnUpdateProductInventoryQuantityAny(
    $companyID: ID!
    $test: Boolean!
  ) {
    onUpdateProductInventoryQuantityAny(companyID: $companyID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      productID
      variantID
      quantity
      resetDate
      shop {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        company {
          id
          updatedAt
          createdAt
          deprecated
          name
          address
          country
          currency
          currencyDecimals
          locale
          vat
        }
        name
        address {
          address
          zipcode
          city
          country
        }
        siret
        vat
        phone
        footnote
        logo
        websiteURL
        brandName
        email
      }
    }
  }
`;
export const onUpdateProductInventoryQuantity = /* GraphQL */ `
  subscription OnUpdateProductInventoryQuantity($shopID: ID!, $test: Boolean!) {
    onUpdateProductInventoryQuantity(shopID: $shopID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      productID
      variantID
      quantity
      resetDate
      shop {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        company {
          id
          updatedAt
          createdAt
          deprecated
          name
          address
          country
          currency
          currencyDecimals
          locale
          vat
        }
        name
        address {
          address
          zipcode
          city
          country
        }
        siret
        vat
        phone
        footnote
        logo
        websiteURL
        brandName
        email
      }
    }
  }
`;
export const onChangeProductInventoryQuantity = /* GraphQL */ `
  subscription OnChangeProductInventoryQuantity($shopID: ID!, $test: Boolean!) {
    onChangeProductInventoryQuantity(shopID: $shopID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      productID
      variantID
      quantity
      resetDate
      shop {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        company {
          id
          updatedAt
          createdAt
          deprecated
          name
          address
          country
          currency
          currencyDecimals
          locale
          vat
        }
        name
        address {
          address
          zipcode
          city
          country
        }
        siret
        vat
        phone
        footnote
        logo
        websiteURL
        brandName
        email
      }
    }
  }
`;
export const onCreatePaymentMethod = /* GraphQL */ `
  subscription OnCreatePaymentMethod($companyID: ID!, $test: Boolean!) {
    onCreatePaymentMethod(companyID: $companyID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      name
      color
      type
      changeType
      countOnCashbookOpeningClosing
      opensCashDrawer
      enabled
    }
  }
`;
export const onUpdatePaymentMethod = /* GraphQL */ `
  subscription OnUpdatePaymentMethod($companyID: ID!, $test: Boolean!) {
    onUpdatePaymentMethod(companyID: $companyID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      name
      color
      type
      changeType
      countOnCashbookOpeningClosing
      opensCashDrawer
      enabled
    }
  }
`;
export const onDeletePaymentMethod = /* GraphQL */ `
  subscription OnDeletePaymentMethod($companyID: ID!, $test: Boolean!) {
    onDeletePaymentMethod(companyID: $companyID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      name
      color
      type
      changeType
      countOnCashbookOpeningClosing
      opensCashDrawer
      enabled
    }
  }
`;
export const onChangePaymentMethod = /* GraphQL */ `
  subscription OnChangePaymentMethod($companyID: ID!, $test: Boolean!) {
    onChangePaymentMethod(companyID: $companyID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      name
      color
      type
      changeType
      countOnCashbookOpeningClosing
      opensCashDrawer
      enabled
    }
  }
`;
export const onCreateCustomer = /* GraphQL */ `
  subscription OnCreateCustomer($companyID: ID!, $test: Boolean!) {
    onCreateCustomer(companyID: $companyID, test: $test) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      test
      firstName
      lastName
      email
      phone
      phoneCode
      companyName
      color
      iconText
      address {
        address
        zipcode
        city
        country
      }
      note
      fidelityCardNumber
      account {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        test
        balance
        movements {
          items {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            state
            type
            amount
            saleID
            paymentID
          }
          nextToken
        }
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          companyID
          test
          customerID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        nbSales
        average {
          total
          totalTax
          totalTaxFree
        }
        index
        totals {
          total
          totalTax
          totalTaxFree
        }
        totalDiscount
      }
    }
  }
`;
export const onUpdateCustomer = /* GraphQL */ `
  subscription OnUpdateCustomer($companyID: ID!, $test: Boolean!) {
    onUpdateCustomer(companyID: $companyID, test: $test) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      test
      firstName
      lastName
      email
      phone
      phoneCode
      companyName
      color
      iconText
      address {
        address
        zipcode
        city
        country
      }
      note
      fidelityCardNumber
      account {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        test
        balance
        movements {
          items {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            state
            type
            amount
            saleID
            paymentID
          }
          nextToken
        }
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          companyID
          test
          customerID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        nbSales
        average {
          total
          totalTax
          totalTaxFree
        }
        index
        totals {
          total
          totalTax
          totalTaxFree
        }
        totalDiscount
      }
    }
  }
`;
export const onDeleteCustomer = /* GraphQL */ `
  subscription OnDeleteCustomer($companyID: ID!, $test: Boolean!) {
    onDeleteCustomer(companyID: $companyID, test: $test) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      test
      firstName
      lastName
      email
      phone
      phoneCode
      companyName
      color
      iconText
      address {
        address
        zipcode
        city
        country
      }
      note
      fidelityCardNumber
      account {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        test
        balance
        movements {
          items {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            state
            type
            amount
            saleID
            paymentID
          }
          nextToken
        }
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          companyID
          test
          customerID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        nbSales
        average {
          total
          totalTax
          totalTaxFree
        }
        index
        totals {
          total
          totalTax
          totalTaxFree
        }
        totalDiscount
      }
    }
  }
`;
export const onChangeCustomer = /* GraphQL */ `
  subscription OnChangeCustomer($companyID: ID!, $test: Boolean!) {
    onChangeCustomer(companyID: $companyID, test: $test) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      test
      firstName
      lastName
      email
      phone
      phoneCode
      companyName
      color
      iconText
      address {
        address
        zipcode
        city
        country
      }
      note
      fidelityCardNumber
      account {
        id
        updatedAt
        createdAt
        deprecated
        companyID
        test
        balance
        movements {
          items {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            state
            type
            amount
            saleID
            paymentID
          }
          nextToken
        }
      }
      customFields {
        items {
          updatedAt
          createdAt
          deprecated
          companyID
          test
          customerID
          customFieldID
          customFieldKey
          customField {
            id
            updatedAt
            createdAt
            deprecated
            catalogID
            test
            key
            objectType
            valueType
            name
            createdByID
          }
          value
          id
        }
        nextToken
      }
      stats {
        nbSales
        average {
          total
          totalTax
          totalTaxFree
        }
        index
        totals {
          total
          totalTax
          totalTaxFree
        }
        totalDiscount
      }
    }
  }
`;
export const onCreateCustomerAccountMovement = /* GraphQL */ `
  subscription OnCreateCustomerAccountMovement(
    $companyID: ID!
    $test: Boolean!
  ) {
    onCreateCustomerAccountMovement(companyID: $companyID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      customerID
      state
      type
      amount
      saleID
      paymentID
      movementInDetails {
        paymentMethodID
        paymentMethodName
        paymentMethodType
      }
    }
  }
`;
export const onUpdateCustomerAccountMovement = /* GraphQL */ `
  subscription OnUpdateCustomerAccountMovement(
    $companyID: ID!
    $test: Boolean!
  ) {
    onUpdateCustomerAccountMovement(companyID: $companyID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      customerID
      state
      type
      amount
      saleID
      paymentID
      movementInDetails {
        paymentMethodID
        paymentMethodName
        paymentMethodType
      }
    }
  }
`;
export const onChangeCustomerAccountMovement = /* GraphQL */ `
  subscription OnChangeCustomerAccountMovement(
    $companyID: ID!
    $test: Boolean!
  ) {
    onChangeCustomerAccountMovement(companyID: $companyID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      customerID
      state
      type
      amount
      saleID
      paymentID
      movementInDetails {
        paymentMethodID
        paymentMethodName
        paymentMethodType
      }
    }
  }
`;
export const onCreateCustomerCustomField = /* GraphQL */ `
  subscription OnCreateCustomerCustomField($companyID: ID!, $test: Boolean!) {
    onCreateCustomerCustomField(companyID: $companyID, test: $test) {
      updatedAt
      createdAt
      deprecated
      companyID
      test
      customerID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const onUpdateCustomerCustomField = /* GraphQL */ `
  subscription OnUpdateCustomerCustomField($companyID: ID!, $test: Boolean!) {
    onUpdateCustomerCustomField(companyID: $companyID, test: $test) {
      updatedAt
      createdAt
      deprecated
      companyID
      test
      customerID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const onDeleteCustomerCustomField = /* GraphQL */ `
  subscription OnDeleteCustomerCustomField($companyID: ID!, $test: Boolean!) {
    onDeleteCustomerCustomField(companyID: $companyID, test: $test) {
      updatedAt
      createdAt
      deprecated
      companyID
      test
      customerID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const onChangeCustomerCustomField = /* GraphQL */ `
  subscription OnChangeCustomerCustomField($companyID: ID!, $test: Boolean!) {
    onChangeCustomerCustomField(companyID: $companyID, test: $test) {
      updatedAt
      createdAt
      deprecated
      companyID
      test
      customerID
      customFieldID
      customFieldKey
      customField {
        id
        updatedAt
        createdAt
        deprecated
        catalogID
        test
        key
        objectType
        valueType
        name
        createdByID
      }
      value
      id
    }
  }
`;
export const onCreateSale = /* GraphQL */ `
  subscription OnCreateSale($companyID: ID!, $test: Boolean!) {
    onCreateSale(companyID: $companyID, test: $test) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      cashbookID
      cashbook {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        number
        state
        total
        totalTaxFree
        minSaleNumber
        maxSaleNumber
        salesCount
        minPaymentNumber
        maxPaymentNumber
        paymentsCount
        openedAt
        closedAt
        openingSellerID
        closingSellerID
        openingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        closingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        note
        movements {
          type
          amount
          motive
          createdAt
        }
        openingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        closingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        totalExpected
        totalDifference
        customFields {
          customFieldID
          name
          valueType
          value
        }
        stats {
          numberOfSales
          minSaleNumber
          maxSaleNumber
          salesCount
          maxPaymentNumber
          minPaymentNumber
          paymentsCount
          taxes {
            id
          }
          payments {
            id
          }
        }
      }
      name
      number
      state
      refundStatus
      refundedStatus
      paymentStatus
      note
      targetID
      targetSale {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        cashbookID
        cashbook {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          number
          state
          total
          totalTaxFree
          minSaleNumber
          maxSaleNumber
          salesCount
          minPaymentNumber
          maxPaymentNumber
          paymentsCount
          openedAt
          closedAt
          openingSellerID
          closingSellerID
          openingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          closingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          note
          movements {
            type
            amount
            motive
            createdAt
          }
          openingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          closingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          totalExpected
          totalDifference
          customFields {
            customFieldID
            name
            valueType
            value
          }
          stats {
            numberOfSales
            minSaleNumber
            maxSaleNumber
            salesCount
            maxPaymentNumber
            minPaymentNumber
            paymentsCount
          }
        }
        name
        number
        state
        refundStatus
        refundedStatus
        paymentStatus
        note
        targetID
        targetSale {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          name
          number
          state
          refundStatus
          refundedStatus
          paymentStatus
          note
          targetID
          targetSale {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          refundSales {
            nextToken
          }
          openedAt
          closedAt
          pendingAt
          customerID
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          sellerID
          seller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          lines {
            quantity
            price
            refundedQuantity
            paymentIndex
            note
            operationType
            state
            targetSaleLineIndex
            motive
            total
            totalSellPrice
            totalDiscount
            totalTaxFree
            totalTax
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
          }
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          payments {
            state
            type
            date
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            paymentID
            integrationPaymentID
            integrationRefundID
          }
          total
          totalDiscount
          totalTaxFree
          totalTax
          totalRest
          stats {
            margin
          }
        }
        refundSales {
          items {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          nextToken
        }
        openedAt
        closedAt
        pendingAt
        customerID
        customer {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          test
          firstName
          lastName
          email
          phone
          phoneCode
          companyName
          color
          iconText
          address {
            address
            zipcode
            city
            country
          }
          note
          fidelityCardNumber
          account {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            test
            balance
          }
          customFields {
            nextToken
          }
          stats {
            nbSales
            index
            totalDiscount
          }
        }
        sellerID
        seller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        lines {
          details {
            ... on SaleLinePackDetails {
              packID
              name
            }
            ... on SaleLineProductDetails {
              productID
              name
              bulkType
              total
              totalTax
              totalTaxFree
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
          }
          quantity
          price
          refundedQuantity
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          paymentIndex
          partialTotals {
            total
            paymentIndex
          }
          note
          operationType
          state
          targetSaleLineIndex
          motive
          total
          totalSellPrice
          totalDiscount
          totalTaxFree
          totalTax
          totalGlobalDiscountFree
          totalTaxFreeGlobalDiscountFree
          totalTaxGlobalDiscountFree
          unitPriceGlobalDiscountFree
          totalDiscountFree
          totalTaxFreeDiscountFree
          totalTaxDiscountFree
          unitPriceDiscountFree
        }
        discounts {
          rate
          type
          discountID
          discountName
          amount
        }
        payments {
          state
          type
          date
          paymentMethodID
          paymentMethodName
          paymentMethodType
          sourcePaymentID
          customerMovementID
          cashbookID
          amount
          totalTaxFree
          totalTax
          taxes {
            taxID
            rate
            inclusive
            total
            totalTax
            totalTaxFree
          }
          paymentID
          payment {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            saleID
            number
            date
            state
            type
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            integrationPaymentID
            integrationRefundID
          }
          integrationPaymentID
          integrationRefundID
        }
        total
        totalDiscount
        totalTaxFree
        totalTax
        totalRest
        stats {
          taxes {
            id
          }
          margin
        }
      }
      refundSales {
        items {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          name
          number
          state
          refundStatus
          refundedStatus
          paymentStatus
          note
          targetID
          targetSale {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          refundSales {
            nextToken
          }
          openedAt
          closedAt
          pendingAt
          customerID
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          sellerID
          seller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          lines {
            quantity
            price
            refundedQuantity
            paymentIndex
            note
            operationType
            state
            targetSaleLineIndex
            motive
            total
            totalSellPrice
            totalDiscount
            totalTaxFree
            totalTax
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
          }
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          payments {
            state
            type
            date
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            paymentID
            integrationPaymentID
            integrationRefundID
          }
          total
          totalDiscount
          totalTaxFree
          totalTax
          totalRest
          stats {
            margin
          }
        }
        nextToken
      }
      openedAt
      closedAt
      pendingAt
      customerID
      customer {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        test
        firstName
        lastName
        email
        phone
        phoneCode
        companyName
        color
        iconText
        address {
          address
          zipcode
          city
          country
        }
        note
        fidelityCardNumber
        account {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          test
          balance
          movements {
            nextToken
          }
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          nbSales
          average {
            total
            totalTax
            totalTaxFree
          }
          index
          totals {
            total
            totalTax
            totalTaxFree
          }
          totalDiscount
        }
      }
      sellerID
      seller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      lines {
        details {
          ... on SaleLinePackDetails {
            packID
            name
            packVariant {
              quantity
              additionalPrice
              basePrice
              total
              totalTaxFree
              totalTax
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
            customFields {
              customFieldID
              name
              value
            }
          }
          ... on SaleLineProductDetails {
            productID
            name
            bulkType
            variant {
              variantID
            }
            category {
              categoryID
              name
            }
            tax {
              taxID
              rate
              inclusive
              total
              totalTax
              totalTaxFree
            }
            tags {
              tagID
              name
            }
            customFields {
              customFieldID
              name
              value
            }
            options {
              optionID
              name
              total
              totalTax
              totalTaxFree
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
            total
            totalTax
            totalTaxFree
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
            virtualDiscounts {
              rate
              type
              discountID
              discountName
              amount
            }
          }
        }
        quantity
        price
        refundedQuantity
        discounts {
          rate
          type
          discountID
          discountName
          amount
        }
        paymentIndex
        partialTotals {
          total
          paymentIndex
        }
        note
        operationType
        state
        targetSaleLineIndex
        motive
        total
        totalSellPrice
        totalDiscount
        totalTaxFree
        totalTax
        totalGlobalDiscountFree
        totalTaxFreeGlobalDiscountFree
        totalTaxGlobalDiscountFree
        unitPriceGlobalDiscountFree
        totalDiscountFree
        totalTaxFreeDiscountFree
        totalTaxDiscountFree
        unitPriceDiscountFree
      }
      discounts {
        rate
        type
        discountID
        discountName
        amount
      }
      payments {
        state
        type
        date
        paymentMethodID
        paymentMethodName
        paymentMethodType
        sourcePaymentID
        customerMovementID
        cashbookID
        amount
        totalTaxFree
        totalTax
        taxes {
          taxID
          rate
          inclusive
          total
          totalTax
          totalTaxFree
        }
        paymentID
        payment {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          saleID
          number
          date
          state
          type
          paymentMethodID
          paymentMethodName
          paymentMethodType
          sourcePaymentID
          customerMovementID
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          amount
          totalTaxFree
          totalTax
          taxes {
            taxID
            rate
            inclusive
            total
            totalTax
            totalTaxFree
          }
          integrationPaymentID
          integrationRefundID
        }
        integrationPaymentID
        integrationRefundID
      }
      total
      totalDiscount
      totalTaxFree
      totalTax
      totalRest
      stats {
        taxes {
          id
          totals {
            total
            totalTax
            totalTaxFree
          }
        }
        margin
      }
    }
  }
`;
export const onUpdateSale = /* GraphQL */ `
  subscription OnUpdateSale($companyID: ID!, $test: Boolean!) {
    onUpdateSale(companyID: $companyID, test: $test) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      cashbookID
      cashbook {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        number
        state
        total
        totalTaxFree
        minSaleNumber
        maxSaleNumber
        salesCount
        minPaymentNumber
        maxPaymentNumber
        paymentsCount
        openedAt
        closedAt
        openingSellerID
        closingSellerID
        openingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        closingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        note
        movements {
          type
          amount
          motive
          createdAt
        }
        openingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        closingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        totalExpected
        totalDifference
        customFields {
          customFieldID
          name
          valueType
          value
        }
        stats {
          numberOfSales
          minSaleNumber
          maxSaleNumber
          salesCount
          maxPaymentNumber
          minPaymentNumber
          paymentsCount
          taxes {
            id
          }
          payments {
            id
          }
        }
      }
      name
      number
      state
      refundStatus
      refundedStatus
      paymentStatus
      note
      targetID
      targetSale {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        cashbookID
        cashbook {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          number
          state
          total
          totalTaxFree
          minSaleNumber
          maxSaleNumber
          salesCount
          minPaymentNumber
          maxPaymentNumber
          paymentsCount
          openedAt
          closedAt
          openingSellerID
          closingSellerID
          openingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          closingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          note
          movements {
            type
            amount
            motive
            createdAt
          }
          openingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          closingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          totalExpected
          totalDifference
          customFields {
            customFieldID
            name
            valueType
            value
          }
          stats {
            numberOfSales
            minSaleNumber
            maxSaleNumber
            salesCount
            maxPaymentNumber
            minPaymentNumber
            paymentsCount
          }
        }
        name
        number
        state
        refundStatus
        refundedStatus
        paymentStatus
        note
        targetID
        targetSale {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          name
          number
          state
          refundStatus
          refundedStatus
          paymentStatus
          note
          targetID
          targetSale {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          refundSales {
            nextToken
          }
          openedAt
          closedAt
          pendingAt
          customerID
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          sellerID
          seller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          lines {
            quantity
            price
            refundedQuantity
            paymentIndex
            note
            operationType
            state
            targetSaleLineIndex
            motive
            total
            totalSellPrice
            totalDiscount
            totalTaxFree
            totalTax
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
          }
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          payments {
            state
            type
            date
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            paymentID
            integrationPaymentID
            integrationRefundID
          }
          total
          totalDiscount
          totalTaxFree
          totalTax
          totalRest
          stats {
            margin
          }
        }
        refundSales {
          items {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          nextToken
        }
        openedAt
        closedAt
        pendingAt
        customerID
        customer {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          test
          firstName
          lastName
          email
          phone
          phoneCode
          companyName
          color
          iconText
          address {
            address
            zipcode
            city
            country
          }
          note
          fidelityCardNumber
          account {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            test
            balance
          }
          customFields {
            nextToken
          }
          stats {
            nbSales
            index
            totalDiscount
          }
        }
        sellerID
        seller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        lines {
          details {
            ... on SaleLinePackDetails {
              packID
              name
            }
            ... on SaleLineProductDetails {
              productID
              name
              bulkType
              total
              totalTax
              totalTaxFree
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
          }
          quantity
          price
          refundedQuantity
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          paymentIndex
          partialTotals {
            total
            paymentIndex
          }
          note
          operationType
          state
          targetSaleLineIndex
          motive
          total
          totalSellPrice
          totalDiscount
          totalTaxFree
          totalTax
          totalGlobalDiscountFree
          totalTaxFreeGlobalDiscountFree
          totalTaxGlobalDiscountFree
          unitPriceGlobalDiscountFree
          totalDiscountFree
          totalTaxFreeDiscountFree
          totalTaxDiscountFree
          unitPriceDiscountFree
        }
        discounts {
          rate
          type
          discountID
          discountName
          amount
        }
        payments {
          state
          type
          date
          paymentMethodID
          paymentMethodName
          paymentMethodType
          sourcePaymentID
          customerMovementID
          cashbookID
          amount
          totalTaxFree
          totalTax
          taxes {
            taxID
            rate
            inclusive
            total
            totalTax
            totalTaxFree
          }
          paymentID
          payment {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            saleID
            number
            date
            state
            type
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            integrationPaymentID
            integrationRefundID
          }
          integrationPaymentID
          integrationRefundID
        }
        total
        totalDiscount
        totalTaxFree
        totalTax
        totalRest
        stats {
          taxes {
            id
          }
          margin
        }
      }
      refundSales {
        items {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          name
          number
          state
          refundStatus
          refundedStatus
          paymentStatus
          note
          targetID
          targetSale {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          refundSales {
            nextToken
          }
          openedAt
          closedAt
          pendingAt
          customerID
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          sellerID
          seller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          lines {
            quantity
            price
            refundedQuantity
            paymentIndex
            note
            operationType
            state
            targetSaleLineIndex
            motive
            total
            totalSellPrice
            totalDiscount
            totalTaxFree
            totalTax
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
          }
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          payments {
            state
            type
            date
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            paymentID
            integrationPaymentID
            integrationRefundID
          }
          total
          totalDiscount
          totalTaxFree
          totalTax
          totalRest
          stats {
            margin
          }
        }
        nextToken
      }
      openedAt
      closedAt
      pendingAt
      customerID
      customer {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        test
        firstName
        lastName
        email
        phone
        phoneCode
        companyName
        color
        iconText
        address {
          address
          zipcode
          city
          country
        }
        note
        fidelityCardNumber
        account {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          test
          balance
          movements {
            nextToken
          }
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          nbSales
          average {
            total
            totalTax
            totalTaxFree
          }
          index
          totals {
            total
            totalTax
            totalTaxFree
          }
          totalDiscount
        }
      }
      sellerID
      seller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      lines {
        details {
          ... on SaleLinePackDetails {
            packID
            name
            packVariant {
              quantity
              additionalPrice
              basePrice
              total
              totalTaxFree
              totalTax
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
            customFields {
              customFieldID
              name
              value
            }
          }
          ... on SaleLineProductDetails {
            productID
            name
            bulkType
            variant {
              variantID
            }
            category {
              categoryID
              name
            }
            tax {
              taxID
              rate
              inclusive
              total
              totalTax
              totalTaxFree
            }
            tags {
              tagID
              name
            }
            customFields {
              customFieldID
              name
              value
            }
            options {
              optionID
              name
              total
              totalTax
              totalTaxFree
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
            total
            totalTax
            totalTaxFree
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
            virtualDiscounts {
              rate
              type
              discountID
              discountName
              amount
            }
          }
        }
        quantity
        price
        refundedQuantity
        discounts {
          rate
          type
          discountID
          discountName
          amount
        }
        paymentIndex
        partialTotals {
          total
          paymentIndex
        }
        note
        operationType
        state
        targetSaleLineIndex
        motive
        total
        totalSellPrice
        totalDiscount
        totalTaxFree
        totalTax
        totalGlobalDiscountFree
        totalTaxFreeGlobalDiscountFree
        totalTaxGlobalDiscountFree
        unitPriceGlobalDiscountFree
        totalDiscountFree
        totalTaxFreeDiscountFree
        totalTaxDiscountFree
        unitPriceDiscountFree
      }
      discounts {
        rate
        type
        discountID
        discountName
        amount
      }
      payments {
        state
        type
        date
        paymentMethodID
        paymentMethodName
        paymentMethodType
        sourcePaymentID
        customerMovementID
        cashbookID
        amount
        totalTaxFree
        totalTax
        taxes {
          taxID
          rate
          inclusive
          total
          totalTax
          totalTaxFree
        }
        paymentID
        payment {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          saleID
          number
          date
          state
          type
          paymentMethodID
          paymentMethodName
          paymentMethodType
          sourcePaymentID
          customerMovementID
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          amount
          totalTaxFree
          totalTax
          taxes {
            taxID
            rate
            inclusive
            total
            totalTax
            totalTaxFree
          }
          integrationPaymentID
          integrationRefundID
        }
        integrationPaymentID
        integrationRefundID
      }
      total
      totalDiscount
      totalTaxFree
      totalTax
      totalRest
      stats {
        taxes {
          id
          totals {
            total
            totalTax
            totalTaxFree
          }
        }
        margin
      }
    }
  }
`;
export const onDeleteSale = /* GraphQL */ `
  subscription OnDeleteSale($companyID: ID!, $test: Boolean!) {
    onDeleteSale(companyID: $companyID, test: $test) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      cashbookID
      cashbook {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        number
        state
        total
        totalTaxFree
        minSaleNumber
        maxSaleNumber
        salesCount
        minPaymentNumber
        maxPaymentNumber
        paymentsCount
        openedAt
        closedAt
        openingSellerID
        closingSellerID
        openingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        closingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        note
        movements {
          type
          amount
          motive
          createdAt
        }
        openingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        closingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        totalExpected
        totalDifference
        customFields {
          customFieldID
          name
          valueType
          value
        }
        stats {
          numberOfSales
          minSaleNumber
          maxSaleNumber
          salesCount
          maxPaymentNumber
          minPaymentNumber
          paymentsCount
          taxes {
            id
          }
          payments {
            id
          }
        }
      }
      name
      number
      state
      refundStatus
      refundedStatus
      paymentStatus
      note
      targetID
      targetSale {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        cashbookID
        cashbook {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          number
          state
          total
          totalTaxFree
          minSaleNumber
          maxSaleNumber
          salesCount
          minPaymentNumber
          maxPaymentNumber
          paymentsCount
          openedAt
          closedAt
          openingSellerID
          closingSellerID
          openingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          closingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          note
          movements {
            type
            amount
            motive
            createdAt
          }
          openingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          closingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          totalExpected
          totalDifference
          customFields {
            customFieldID
            name
            valueType
            value
          }
          stats {
            numberOfSales
            minSaleNumber
            maxSaleNumber
            salesCount
            maxPaymentNumber
            minPaymentNumber
            paymentsCount
          }
        }
        name
        number
        state
        refundStatus
        refundedStatus
        paymentStatus
        note
        targetID
        targetSale {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          name
          number
          state
          refundStatus
          refundedStatus
          paymentStatus
          note
          targetID
          targetSale {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          refundSales {
            nextToken
          }
          openedAt
          closedAt
          pendingAt
          customerID
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          sellerID
          seller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          lines {
            quantity
            price
            refundedQuantity
            paymentIndex
            note
            operationType
            state
            targetSaleLineIndex
            motive
            total
            totalSellPrice
            totalDiscount
            totalTaxFree
            totalTax
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
          }
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          payments {
            state
            type
            date
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            paymentID
            integrationPaymentID
            integrationRefundID
          }
          total
          totalDiscount
          totalTaxFree
          totalTax
          totalRest
          stats {
            margin
          }
        }
        refundSales {
          items {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          nextToken
        }
        openedAt
        closedAt
        pendingAt
        customerID
        customer {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          test
          firstName
          lastName
          email
          phone
          phoneCode
          companyName
          color
          iconText
          address {
            address
            zipcode
            city
            country
          }
          note
          fidelityCardNumber
          account {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            test
            balance
          }
          customFields {
            nextToken
          }
          stats {
            nbSales
            index
            totalDiscount
          }
        }
        sellerID
        seller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        lines {
          details {
            ... on SaleLinePackDetails {
              packID
              name
            }
            ... on SaleLineProductDetails {
              productID
              name
              bulkType
              total
              totalTax
              totalTaxFree
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
          }
          quantity
          price
          refundedQuantity
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          paymentIndex
          partialTotals {
            total
            paymentIndex
          }
          note
          operationType
          state
          targetSaleLineIndex
          motive
          total
          totalSellPrice
          totalDiscount
          totalTaxFree
          totalTax
          totalGlobalDiscountFree
          totalTaxFreeGlobalDiscountFree
          totalTaxGlobalDiscountFree
          unitPriceGlobalDiscountFree
          totalDiscountFree
          totalTaxFreeDiscountFree
          totalTaxDiscountFree
          unitPriceDiscountFree
        }
        discounts {
          rate
          type
          discountID
          discountName
          amount
        }
        payments {
          state
          type
          date
          paymentMethodID
          paymentMethodName
          paymentMethodType
          sourcePaymentID
          customerMovementID
          cashbookID
          amount
          totalTaxFree
          totalTax
          taxes {
            taxID
            rate
            inclusive
            total
            totalTax
            totalTaxFree
          }
          paymentID
          payment {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            saleID
            number
            date
            state
            type
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            integrationPaymentID
            integrationRefundID
          }
          integrationPaymentID
          integrationRefundID
        }
        total
        totalDiscount
        totalTaxFree
        totalTax
        totalRest
        stats {
          taxes {
            id
          }
          margin
        }
      }
      refundSales {
        items {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          name
          number
          state
          refundStatus
          refundedStatus
          paymentStatus
          note
          targetID
          targetSale {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          refundSales {
            nextToken
          }
          openedAt
          closedAt
          pendingAt
          customerID
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          sellerID
          seller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          lines {
            quantity
            price
            refundedQuantity
            paymentIndex
            note
            operationType
            state
            targetSaleLineIndex
            motive
            total
            totalSellPrice
            totalDiscount
            totalTaxFree
            totalTax
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
          }
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          payments {
            state
            type
            date
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            paymentID
            integrationPaymentID
            integrationRefundID
          }
          total
          totalDiscount
          totalTaxFree
          totalTax
          totalRest
          stats {
            margin
          }
        }
        nextToken
      }
      openedAt
      closedAt
      pendingAt
      customerID
      customer {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        test
        firstName
        lastName
        email
        phone
        phoneCode
        companyName
        color
        iconText
        address {
          address
          zipcode
          city
          country
        }
        note
        fidelityCardNumber
        account {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          test
          balance
          movements {
            nextToken
          }
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          nbSales
          average {
            total
            totalTax
            totalTaxFree
          }
          index
          totals {
            total
            totalTax
            totalTaxFree
          }
          totalDiscount
        }
      }
      sellerID
      seller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      lines {
        details {
          ... on SaleLinePackDetails {
            packID
            name
            packVariant {
              quantity
              additionalPrice
              basePrice
              total
              totalTaxFree
              totalTax
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
            customFields {
              customFieldID
              name
              value
            }
          }
          ... on SaleLineProductDetails {
            productID
            name
            bulkType
            variant {
              variantID
            }
            category {
              categoryID
              name
            }
            tax {
              taxID
              rate
              inclusive
              total
              totalTax
              totalTaxFree
            }
            tags {
              tagID
              name
            }
            customFields {
              customFieldID
              name
              value
            }
            options {
              optionID
              name
              total
              totalTax
              totalTaxFree
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
            total
            totalTax
            totalTaxFree
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
            virtualDiscounts {
              rate
              type
              discountID
              discountName
              amount
            }
          }
        }
        quantity
        price
        refundedQuantity
        discounts {
          rate
          type
          discountID
          discountName
          amount
        }
        paymentIndex
        partialTotals {
          total
          paymentIndex
        }
        note
        operationType
        state
        targetSaleLineIndex
        motive
        total
        totalSellPrice
        totalDiscount
        totalTaxFree
        totalTax
        totalGlobalDiscountFree
        totalTaxFreeGlobalDiscountFree
        totalTaxGlobalDiscountFree
        unitPriceGlobalDiscountFree
        totalDiscountFree
        totalTaxFreeDiscountFree
        totalTaxDiscountFree
        unitPriceDiscountFree
      }
      discounts {
        rate
        type
        discountID
        discountName
        amount
      }
      payments {
        state
        type
        date
        paymentMethodID
        paymentMethodName
        paymentMethodType
        sourcePaymentID
        customerMovementID
        cashbookID
        amount
        totalTaxFree
        totalTax
        taxes {
          taxID
          rate
          inclusive
          total
          totalTax
          totalTaxFree
        }
        paymentID
        payment {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          saleID
          number
          date
          state
          type
          paymentMethodID
          paymentMethodName
          paymentMethodType
          sourcePaymentID
          customerMovementID
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          amount
          totalTaxFree
          totalTax
          taxes {
            taxID
            rate
            inclusive
            total
            totalTax
            totalTaxFree
          }
          integrationPaymentID
          integrationRefundID
        }
        integrationPaymentID
        integrationRefundID
      }
      total
      totalDiscount
      totalTaxFree
      totalTax
      totalRest
      stats {
        taxes {
          id
          totals {
            total
            totalTax
            totalTaxFree
          }
        }
        margin
      }
    }
  }
`;
export const onChangeSale = /* GraphQL */ `
  subscription OnChangeSale($companyID: ID!, $test: Boolean!) {
    onChangeSale(companyID: $companyID, test: $test) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      cashbookID
      cashbook {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        number
        state
        total
        totalTaxFree
        minSaleNumber
        maxSaleNumber
        salesCount
        minPaymentNumber
        maxPaymentNumber
        paymentsCount
        openedAt
        closedAt
        openingSellerID
        closingSellerID
        openingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        closingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        note
        movements {
          type
          amount
          motive
          createdAt
        }
        openingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        closingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        totalExpected
        totalDifference
        customFields {
          customFieldID
          name
          valueType
          value
        }
        stats {
          numberOfSales
          minSaleNumber
          maxSaleNumber
          salesCount
          maxPaymentNumber
          minPaymentNumber
          paymentsCount
          taxes {
            id
          }
          payments {
            id
          }
        }
      }
      name
      number
      state
      refundStatus
      refundedStatus
      paymentStatus
      note
      targetID
      targetSale {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        cashbookID
        cashbook {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          number
          state
          total
          totalTaxFree
          minSaleNumber
          maxSaleNumber
          salesCount
          minPaymentNumber
          maxPaymentNumber
          paymentsCount
          openedAt
          closedAt
          openingSellerID
          closingSellerID
          openingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          closingSeller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          note
          movements {
            type
            amount
            motive
            createdAt
          }
          openingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          closingCashFund {
            paymentMethodID
            paymentMethodName
            paymentMethodType
            total
          }
          totalExpected
          totalDifference
          customFields {
            customFieldID
            name
            valueType
            value
          }
          stats {
            numberOfSales
            minSaleNumber
            maxSaleNumber
            salesCount
            maxPaymentNumber
            minPaymentNumber
            paymentsCount
          }
        }
        name
        number
        state
        refundStatus
        refundedStatus
        paymentStatus
        note
        targetID
        targetSale {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          name
          number
          state
          refundStatus
          refundedStatus
          paymentStatus
          note
          targetID
          targetSale {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          refundSales {
            nextToken
          }
          openedAt
          closedAt
          pendingAt
          customerID
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          sellerID
          seller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          lines {
            quantity
            price
            refundedQuantity
            paymentIndex
            note
            operationType
            state
            targetSaleLineIndex
            motive
            total
            totalSellPrice
            totalDiscount
            totalTaxFree
            totalTax
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
          }
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          payments {
            state
            type
            date
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            paymentID
            integrationPaymentID
            integrationRefundID
          }
          total
          totalDiscount
          totalTaxFree
          totalTax
          totalRest
          stats {
            margin
          }
        }
        refundSales {
          items {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          nextToken
        }
        openedAt
        closedAt
        pendingAt
        customerID
        customer {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          test
          firstName
          lastName
          email
          phone
          phoneCode
          companyName
          color
          iconText
          address {
            address
            zipcode
            city
            country
          }
          note
          fidelityCardNumber
          account {
            id
            updatedAt
            createdAt
            deprecated
            companyID
            test
            balance
          }
          customFields {
            nextToken
          }
          stats {
            nbSales
            index
            totalDiscount
          }
        }
        sellerID
        seller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        lines {
          details {
            ... on SaleLinePackDetails {
              packID
              name
            }
            ... on SaleLineProductDetails {
              productID
              name
              bulkType
              total
              totalTax
              totalTaxFree
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
          }
          quantity
          price
          refundedQuantity
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          paymentIndex
          partialTotals {
            total
            paymentIndex
          }
          note
          operationType
          state
          targetSaleLineIndex
          motive
          total
          totalSellPrice
          totalDiscount
          totalTaxFree
          totalTax
          totalGlobalDiscountFree
          totalTaxFreeGlobalDiscountFree
          totalTaxGlobalDiscountFree
          unitPriceGlobalDiscountFree
          totalDiscountFree
          totalTaxFreeDiscountFree
          totalTaxDiscountFree
          unitPriceDiscountFree
        }
        discounts {
          rate
          type
          discountID
          discountName
          amount
        }
        payments {
          state
          type
          date
          paymentMethodID
          paymentMethodName
          paymentMethodType
          sourcePaymentID
          customerMovementID
          cashbookID
          amount
          totalTaxFree
          totalTax
          taxes {
            taxID
            rate
            inclusive
            total
            totalTax
            totalTaxFree
          }
          paymentID
          payment {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            saleID
            number
            date
            state
            type
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            integrationPaymentID
            integrationRefundID
          }
          integrationPaymentID
          integrationRefundID
        }
        total
        totalDiscount
        totalTaxFree
        totalTax
        totalRest
        stats {
          taxes {
            id
          }
          margin
        }
      }
      refundSales {
        items {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          name
          number
          state
          refundStatus
          refundedStatus
          paymentStatus
          note
          targetID
          targetSale {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            cashbookID
            name
            number
            state
            refundStatus
            refundedStatus
            paymentStatus
            note
            targetID
            openedAt
            closedAt
            pendingAt
            customerID
            sellerID
            total
            totalDiscount
            totalTaxFree
            totalTax
            totalRest
          }
          refundSales {
            nextToken
          }
          openedAt
          closedAt
          pendingAt
          customerID
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          sellerID
          seller {
            id
            updatedAt
            createdAt
            deprecated
            firstName
            lastName
            color
            iconText
            photo
            email
          }
          lines {
            quantity
            price
            refundedQuantity
            paymentIndex
            note
            operationType
            state
            targetSaleLineIndex
            motive
            total
            totalSellPrice
            totalDiscount
            totalTaxFree
            totalTax
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
          }
          discounts {
            rate
            type
            discountID
            discountName
            amount
          }
          payments {
            state
            type
            date
            paymentMethodID
            paymentMethodName
            paymentMethodType
            sourcePaymentID
            customerMovementID
            cashbookID
            amount
            totalTaxFree
            totalTax
            paymentID
            integrationPaymentID
            integrationRefundID
          }
          total
          totalDiscount
          totalTaxFree
          totalTax
          totalRest
          stats {
            margin
          }
        }
        nextToken
      }
      openedAt
      closedAt
      pendingAt
      customerID
      customer {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        test
        firstName
        lastName
        email
        phone
        phoneCode
        companyName
        color
        iconText
        address {
          address
          zipcode
          city
          country
        }
        note
        fidelityCardNumber
        account {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          test
          balance
          movements {
            nextToken
          }
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          nbSales
          average {
            total
            totalTax
            totalTaxFree
          }
          index
          totals {
            total
            totalTax
            totalTaxFree
          }
          totalDiscount
        }
      }
      sellerID
      seller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      lines {
        details {
          ... on SaleLinePackDetails {
            packID
            name
            packVariant {
              quantity
              additionalPrice
              basePrice
              total
              totalTaxFree
              totalTax
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
            customFields {
              customFieldID
              name
              value
            }
          }
          ... on SaleLineProductDetails {
            productID
            name
            bulkType
            variant {
              variantID
            }
            category {
              categoryID
              name
            }
            tax {
              taxID
              rate
              inclusive
              total
              totalTax
              totalTaxFree
            }
            tags {
              tagID
              name
            }
            customFields {
              customFieldID
              name
              value
            }
            options {
              optionID
              name
              total
              totalTax
              totalTaxFree
              totalGlobalDiscountFree
              totalTaxFreeGlobalDiscountFree
              totalTaxGlobalDiscountFree
              unitPriceGlobalDiscountFree
              totalDiscountFree
              totalTaxFreeDiscountFree
              totalTaxDiscountFree
              unitPriceDiscountFree
            }
            total
            totalTax
            totalTaxFree
            totalGlobalDiscountFree
            totalTaxFreeGlobalDiscountFree
            totalTaxGlobalDiscountFree
            unitPriceGlobalDiscountFree
            totalDiscountFree
            totalTaxFreeDiscountFree
            totalTaxDiscountFree
            unitPriceDiscountFree
            virtualDiscounts {
              rate
              type
              discountID
              discountName
              amount
            }
          }
        }
        quantity
        price
        refundedQuantity
        discounts {
          rate
          type
          discountID
          discountName
          amount
        }
        paymentIndex
        partialTotals {
          total
          paymentIndex
        }
        note
        operationType
        state
        targetSaleLineIndex
        motive
        total
        totalSellPrice
        totalDiscount
        totalTaxFree
        totalTax
        totalGlobalDiscountFree
        totalTaxFreeGlobalDiscountFree
        totalTaxGlobalDiscountFree
        unitPriceGlobalDiscountFree
        totalDiscountFree
        totalTaxFreeDiscountFree
        totalTaxDiscountFree
        unitPriceDiscountFree
      }
      discounts {
        rate
        type
        discountID
        discountName
        amount
      }
      payments {
        state
        type
        date
        paymentMethodID
        paymentMethodName
        paymentMethodType
        sourcePaymentID
        customerMovementID
        cashbookID
        amount
        totalTaxFree
        totalTax
        taxes {
          taxID
          rate
          inclusive
          total
          totalTax
          totalTaxFree
        }
        paymentID
        payment {
          id
          originalID
          updatedAt
          createdAt
          deprecated
          companyID
          shopID
          test
          sourceID
          sourceName
          saleID
          number
          date
          state
          type
          paymentMethodID
          paymentMethodName
          paymentMethodType
          sourcePaymentID
          customerMovementID
          cashbookID
          cashbook {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            shopID
            test
            sourceID
            sourceName
            number
            state
            total
            totalTaxFree
            minSaleNumber
            maxSaleNumber
            salesCount
            minPaymentNumber
            maxPaymentNumber
            paymentsCount
            openedAt
            closedAt
            openingSellerID
            closingSellerID
            note
            totalExpected
            totalDifference
          }
          customer {
            id
            originalID
            updatedAt
            createdAt
            deprecated
            companyID
            test
            firstName
            lastName
            email
            phone
            phoneCode
            companyName
            color
            iconText
            note
            fidelityCardNumber
          }
          amount
          totalTaxFree
          totalTax
          taxes {
            taxID
            rate
            inclusive
            total
            totalTax
            totalTaxFree
          }
          integrationPaymentID
          integrationRefundID
        }
        integrationPaymentID
        integrationRefundID
      }
      total
      totalDiscount
      totalTaxFree
      totalTax
      totalRest
      stats {
        taxes {
          id
          totals {
            total
            totalTax
            totalTaxFree
          }
        }
        margin
      }
    }
  }
`;
export const onCreateCashbook = /* GraphQL */ `
  subscription OnCreateCashbook($companyID: ID!, $test: Boolean!) {
    onCreateCashbook(companyID: $companyID, test: $test) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      number
      state
      total
      totalTaxFree
      minSaleNumber
      maxSaleNumber
      salesCount
      minPaymentNumber
      maxPaymentNumber
      paymentsCount
      openedAt
      closedAt
      openingSellerID
      closingSellerID
      openingSeller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      closingSeller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      note
      movements {
        type
        amount
        motive
        createdAt
      }
      openingCashFund {
        paymentMethodID
        paymentMethodName
        paymentMethodType
        total
      }
      closingCashFund {
        paymentMethodID
        paymentMethodName
        paymentMethodType
        total
      }
      totalExpected
      totalDifference
      customFields {
        customFieldID
        name
        valueType
        value
      }
      stats {
        numberOfSales
        minSaleNumber
        maxSaleNumber
        salesCount
        maxPaymentNumber
        minPaymentNumber
        paymentsCount
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        taxes {
          id
          totals {
            total
            totalTax
            totalTaxFree
          }
        }
        payments {
          id
          detail {
            amount
            quantity
          }
          sellDetail {
            amount
            quantity
          }
          refundDetail {
            amount
            quantity
          }
        }
      }
    }
  }
`;
export const onUpdateCashbook = /* GraphQL */ `
  subscription OnUpdateCashbook($companyID: ID!, $test: Boolean!) {
    onUpdateCashbook(companyID: $companyID, test: $test) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      number
      state
      total
      totalTaxFree
      minSaleNumber
      maxSaleNumber
      salesCount
      minPaymentNumber
      maxPaymentNumber
      paymentsCount
      openedAt
      closedAt
      openingSellerID
      closingSellerID
      openingSeller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      closingSeller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      note
      movements {
        type
        amount
        motive
        createdAt
      }
      openingCashFund {
        paymentMethodID
        paymentMethodName
        paymentMethodType
        total
      }
      closingCashFund {
        paymentMethodID
        paymentMethodName
        paymentMethodType
        total
      }
      totalExpected
      totalDifference
      customFields {
        customFieldID
        name
        valueType
        value
      }
      stats {
        numberOfSales
        minSaleNumber
        maxSaleNumber
        salesCount
        maxPaymentNumber
        minPaymentNumber
        paymentsCount
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        taxes {
          id
          totals {
            total
            totalTax
            totalTaxFree
          }
        }
        payments {
          id
          detail {
            amount
            quantity
          }
          sellDetail {
            amount
            quantity
          }
          refundDetail {
            amount
            quantity
          }
        }
      }
    }
  }
`;
export const onDeleteCashbook = /* GraphQL */ `
  subscription OnDeleteCashbook($companyID: ID!, $test: Boolean!) {
    onDeleteCashbook(companyID: $companyID, test: $test) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      number
      state
      total
      totalTaxFree
      minSaleNumber
      maxSaleNumber
      salesCount
      minPaymentNumber
      maxPaymentNumber
      paymentsCount
      openedAt
      closedAt
      openingSellerID
      closingSellerID
      openingSeller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      closingSeller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      note
      movements {
        type
        amount
        motive
        createdAt
      }
      openingCashFund {
        paymentMethodID
        paymentMethodName
        paymentMethodType
        total
      }
      closingCashFund {
        paymentMethodID
        paymentMethodName
        paymentMethodType
        total
      }
      totalExpected
      totalDifference
      customFields {
        customFieldID
        name
        valueType
        value
      }
      stats {
        numberOfSales
        minSaleNumber
        maxSaleNumber
        salesCount
        maxPaymentNumber
        minPaymentNumber
        paymentsCount
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        taxes {
          id
          totals {
            total
            totalTax
            totalTaxFree
          }
        }
        payments {
          id
          detail {
            amount
            quantity
          }
          sellDetail {
            amount
            quantity
          }
          refundDetail {
            amount
            quantity
          }
        }
      }
    }
  }
`;
export const onChangeCashbook = /* GraphQL */ `
  subscription OnChangeCashbook($companyID: ID!, $test: Boolean!) {
    onChangeCashbook(companyID: $companyID, test: $test) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      number
      state
      total
      totalTaxFree
      minSaleNumber
      maxSaleNumber
      salesCount
      minPaymentNumber
      maxPaymentNumber
      paymentsCount
      openedAt
      closedAt
      openingSellerID
      closingSellerID
      openingSeller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      closingSeller {
        id
        updatedAt
        createdAt
        deprecated
        firstName
        lastName
        color
        iconText
        photo
        email
      }
      note
      movements {
        type
        amount
        motive
        createdAt
      }
      openingCashFund {
        paymentMethodID
        paymentMethodName
        paymentMethodType
        total
      }
      closingCashFund {
        paymentMethodID
        paymentMethodName
        paymentMethodType
        total
      }
      totalExpected
      totalDifference
      customFields {
        customFieldID
        name
        valueType
        value
      }
      stats {
        numberOfSales
        minSaleNumber
        maxSaleNumber
        salesCount
        maxPaymentNumber
        minPaymentNumber
        paymentsCount
        totalsDetail {
          totals {
            total
            totalTax
            totalTaxFree
          }
          sellTotals {
            total
            totalTax
            totalTaxFree
          }
          refundTotals {
            total
            totalTax
            totalTaxFree
          }
        }
        taxes {
          id
          totals {
            total
            totalTax
            totalTaxFree
          }
        }
        payments {
          id
          detail {
            amount
            quantity
          }
          sellDetail {
            amount
            quantity
          }
          refundDetail {
            amount
            quantity
          }
        }
      }
    }
  }
`;
export const onCreatePayment = /* GraphQL */ `
  subscription OnCreatePayment($companyID: ID!, $test: Boolean!) {
    onCreatePayment(companyID: $companyID, test: $test) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      saleID
      number
      date
      state
      type
      paymentMethodID
      paymentMethodName
      paymentMethodType
      sourcePaymentID
      customerMovementID
      cashbookID
      cashbook {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        number
        state
        total
        totalTaxFree
        minSaleNumber
        maxSaleNumber
        salesCount
        minPaymentNumber
        maxPaymentNumber
        paymentsCount
        openedAt
        closedAt
        openingSellerID
        closingSellerID
        openingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        closingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        note
        movements {
          type
          amount
          motive
          createdAt
        }
        openingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        closingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        totalExpected
        totalDifference
        customFields {
          customFieldID
          name
          valueType
          value
        }
        stats {
          numberOfSales
          minSaleNumber
          maxSaleNumber
          salesCount
          maxPaymentNumber
          minPaymentNumber
          paymentsCount
          taxes {
            id
          }
          payments {
            id
          }
        }
      }
      customer {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        test
        firstName
        lastName
        email
        phone
        phoneCode
        companyName
        color
        iconText
        address {
          address
          zipcode
          city
          country
        }
        note
        fidelityCardNumber
        account {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          test
          balance
          movements {
            nextToken
          }
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          nbSales
          average {
            total
            totalTax
            totalTaxFree
          }
          index
          totals {
            total
            totalTax
            totalTaxFree
          }
          totalDiscount
        }
      }
      amount
      totalTaxFree
      totalTax
      taxes {
        taxID
        rate
        inclusive
        total
        totalTax
        totalTaxFree
      }
      integrationPaymentID
      integrationRefundID
    }
  }
`;
export const onUpdatePayment = /* GraphQL */ `
  subscription OnUpdatePayment($companyID: ID!, $test: Boolean!) {
    onUpdatePayment(companyID: $companyID, test: $test) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      saleID
      number
      date
      state
      type
      paymentMethodID
      paymentMethodName
      paymentMethodType
      sourcePaymentID
      customerMovementID
      cashbookID
      cashbook {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        number
        state
        total
        totalTaxFree
        minSaleNumber
        maxSaleNumber
        salesCount
        minPaymentNumber
        maxPaymentNumber
        paymentsCount
        openedAt
        closedAt
        openingSellerID
        closingSellerID
        openingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        closingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        note
        movements {
          type
          amount
          motive
          createdAt
        }
        openingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        closingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        totalExpected
        totalDifference
        customFields {
          customFieldID
          name
          valueType
          value
        }
        stats {
          numberOfSales
          minSaleNumber
          maxSaleNumber
          salesCount
          maxPaymentNumber
          minPaymentNumber
          paymentsCount
          taxes {
            id
          }
          payments {
            id
          }
        }
      }
      customer {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        test
        firstName
        lastName
        email
        phone
        phoneCode
        companyName
        color
        iconText
        address {
          address
          zipcode
          city
          country
        }
        note
        fidelityCardNumber
        account {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          test
          balance
          movements {
            nextToken
          }
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          nbSales
          average {
            total
            totalTax
            totalTaxFree
          }
          index
          totals {
            total
            totalTax
            totalTaxFree
          }
          totalDiscount
        }
      }
      amount
      totalTaxFree
      totalTax
      taxes {
        taxID
        rate
        inclusive
        total
        totalTax
        totalTaxFree
      }
      integrationPaymentID
      integrationRefundID
    }
  }
`;
export const onDeletePayment = /* GraphQL */ `
  subscription OnDeletePayment($companyID: ID!, $test: Boolean!) {
    onDeletePayment(companyID: $companyID, test: $test) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      saleID
      number
      date
      state
      type
      paymentMethodID
      paymentMethodName
      paymentMethodType
      sourcePaymentID
      customerMovementID
      cashbookID
      cashbook {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        number
        state
        total
        totalTaxFree
        minSaleNumber
        maxSaleNumber
        salesCount
        minPaymentNumber
        maxPaymentNumber
        paymentsCount
        openedAt
        closedAt
        openingSellerID
        closingSellerID
        openingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        closingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        note
        movements {
          type
          amount
          motive
          createdAt
        }
        openingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        closingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        totalExpected
        totalDifference
        customFields {
          customFieldID
          name
          valueType
          value
        }
        stats {
          numberOfSales
          minSaleNumber
          maxSaleNumber
          salesCount
          maxPaymentNumber
          minPaymentNumber
          paymentsCount
          taxes {
            id
          }
          payments {
            id
          }
        }
      }
      customer {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        test
        firstName
        lastName
        email
        phone
        phoneCode
        companyName
        color
        iconText
        address {
          address
          zipcode
          city
          country
        }
        note
        fidelityCardNumber
        account {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          test
          balance
          movements {
            nextToken
          }
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          nbSales
          average {
            total
            totalTax
            totalTaxFree
          }
          index
          totals {
            total
            totalTax
            totalTaxFree
          }
          totalDiscount
        }
      }
      amount
      totalTaxFree
      totalTax
      taxes {
        taxID
        rate
        inclusive
        total
        totalTax
        totalTaxFree
      }
      integrationPaymentID
      integrationRefundID
    }
  }
`;
export const onChangePayment = /* GraphQL */ `
  subscription OnChangePayment($companyID: ID!, $test: Boolean!) {
    onChangePayment(companyID: $companyID, test: $test) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      test
      sourceID
      sourceName
      saleID
      number
      date
      state
      type
      paymentMethodID
      paymentMethodName
      paymentMethodType
      sourcePaymentID
      customerMovementID
      cashbookID
      cashbook {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        shopID
        test
        sourceID
        sourceName
        number
        state
        total
        totalTaxFree
        minSaleNumber
        maxSaleNumber
        salesCount
        minPaymentNumber
        maxPaymentNumber
        paymentsCount
        openedAt
        closedAt
        openingSellerID
        closingSellerID
        openingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        closingSeller {
          id
          updatedAt
          createdAt
          deprecated
          firstName
          lastName
          color
          iconText
          photo
          email
        }
        note
        movements {
          type
          amount
          motive
          createdAt
        }
        openingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        closingCashFund {
          paymentMethodID
          paymentMethodName
          paymentMethodType
          total
        }
        totalExpected
        totalDifference
        customFields {
          customFieldID
          name
          valueType
          value
        }
        stats {
          numberOfSales
          minSaleNumber
          maxSaleNumber
          salesCount
          maxPaymentNumber
          minPaymentNumber
          paymentsCount
          taxes {
            id
          }
          payments {
            id
          }
        }
      }
      customer {
        id
        originalID
        updatedAt
        createdAt
        deprecated
        companyID
        test
        firstName
        lastName
        email
        phone
        phoneCode
        companyName
        color
        iconText
        address {
          address
          zipcode
          city
          country
        }
        note
        fidelityCardNumber
        account {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          test
          balance
          movements {
            nextToken
          }
        }
        customFields {
          items {
            updatedAt
            createdAt
            deprecated
            companyID
            test
            customerID
            customFieldID
            customFieldKey
            value
            id
          }
          nextToken
        }
        stats {
          nbSales
          average {
            total
            totalTax
            totalTaxFree
          }
          index
          totals {
            total
            totalTax
            totalTaxFree
          }
          totalDiscount
        }
      }
      amount
      totalTaxFree
      totalTax
      taxes {
        taxID
        rate
        inclusive
        total
        totalTax
        totalTaxFree
      }
      integrationPaymentID
      integrationRefundID
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent($companyID: ID!, $objectID: ID) {
    onCreateEvent(companyID: $companyID, objectID: $objectID) {
      id
      originalID
      updatedAt
      createdAt
      deprecated
      companyID
      shopID
      slotID
      userID
      test
      version
      date
      type
      code
      objectType
      objectID
      action
      actionData
      hash
    }
  }
`;
export const onCreateBackgroundJob = /* GraphQL */ `
  subscription OnCreateBackgroundJob($companyID: ID!, $test: Boolean!) {
    onCreateBackgroundJob(companyID: $companyID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      shopID
      sourceEmail
      type
      state
      details {
        ... on CatalogMigrationDetails {
          PRODUCT {
            total
            successCount
            errorCount
          }
          PRODUCT_VARIANT {
            total
            successCount
            errorCount
          }
          CATEGORY {
            total
            successCount
            errorCount
          }
          TAX {
            total
            successCount
            errorCount
          }
          DISCOUNT {
            total
            successCount
            errorCount
          }
        }
        ... on CustomerMigrationDetails {
          CUSTOMER {
            total
            successCount
            errorCount
          }
        }
        ... on InventoryMigrationDetails {
          INVENTORY {
            total
            successCount
            errorCount
          }
        }
        ... on ActivityMigrationDetails {
          PAYMENT_METHOD {
            total
            successCount
            errorCount
          }
          SLOT {
            total
            successCount
            errorCount
          }
          CASHBOOK {
            total
            successCount
            errorCount
          }
          SALE {
            total
            successCount
            errorCount
          }
          PAYMENT {
            total
            successCount
            errorCount
          }
        }
        ... on ImportDetails {
          IMPORT {
            total
            successCount
            errorCount
          }
        }
      }
      message
      reportUrl
      flatfileBatchId
      flatfileSheetId
    }
  }
`;
export const onUpdateBackgroundJob = /* GraphQL */ `
  subscription OnUpdateBackgroundJob($companyID: ID!, $test: Boolean!) {
    onUpdateBackgroundJob(companyID: $companyID, test: $test) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      test
      shopID
      sourceEmail
      type
      state
      details {
        ... on CatalogMigrationDetails {
          PRODUCT {
            total
            successCount
            errorCount
          }
          PRODUCT_VARIANT {
            total
            successCount
            errorCount
          }
          CATEGORY {
            total
            successCount
            errorCount
          }
          TAX {
            total
            successCount
            errorCount
          }
          DISCOUNT {
            total
            successCount
            errorCount
          }
        }
        ... on CustomerMigrationDetails {
          CUSTOMER {
            total
            successCount
            errorCount
          }
        }
        ... on InventoryMigrationDetails {
          INVENTORY {
            total
            successCount
            errorCount
          }
        }
        ... on ActivityMigrationDetails {
          PAYMENT_METHOD {
            total
            successCount
            errorCount
          }
          SLOT {
            total
            successCount
            errorCount
          }
          CASHBOOK {
            total
            successCount
            errorCount
          }
          SALE {
            total
            successCount
            errorCount
          }
          PAYMENT {
            total
            successCount
            errorCount
          }
        }
        ... on ImportDetails {
          IMPORT {
            total
            successCount
            errorCount
          }
        }
      }
      message
      reportUrl
      flatfileBatchId
      flatfileSheetId
    }
  }
`;
export const onChangeAccountInvitation = /* GraphQL */ `
  subscription OnChangeAccountInvitation($companyID: ID!) {
    onChangeAccountInvitation(companyID: $companyID) {
      id
      updatedAt
      createdAt
      deprecated
      companyID
      companyName
      email
      role
      shops
      expiresAt
      status
      senderID
      senderName
      senderEmail
    }
  }
`;
export const onChangeUser = /* GraphQL */ `
  subscription OnChangeUser($companyID: ID!) {
    onChangeUser(companyID: $companyID) {
      id
      updatedAt
      createdAt
      deprecated
      firstName
      lastName
      color
      iconText
      photo
      email
      company {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        company {
          id
          updatedAt
          createdAt
          deprecated
          name
          address
          country
          currency
          currencyDecimals
          locale
          vat
        }
        owner
        role
        read
        update
        billing
        billingExport
        shopWrite
        deviceSlotWrite
        deviceSlotUse
        paymentMethodRead
        paymentMethodCreate
        paymentMethodUpdate
        paymentMethodDelete
        customerRead
        customerCreate
        customerUpdate
        customerDelete
        customerExport
        inventoryRead
        cashbookRead
        saleRead
        paymentRead
        userInvite
        userRead
        userUpdate
        userRemove
        sell
        sync
        id
      }
      catalog {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        catalogID
        catalog {
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          id
        }
        read
        create
        update
        delete
        sync
        id
      }
      shops {
        updatedAt
        createdAt
        deprecated
        accountID
        companyID
        shopID
        shop {
          id
          updatedAt
          createdAt
          deprecated
          companyID
          company {
            id
            updatedAt
            createdAt
            deprecated
            name
            address
            country
            currency
            currencyDecimals
            locale
            vat
          }
          name
          address {
            address
            zipcode
            city
            country
          }
          siret
          vat
          phone
          footnote
          logo
          websiteURL
          brandName
          email
        }
        read
        update
        delete
        inventoryRead
        inventoryWrite
        cashbookRead
        cashbookWrite
        saleRead
        saleWrite
        paymentRead
        paymentWrite
        userInvite
        userRead
        userUpdate
        userRemove
        activitiesExport
        tillCashbooksRead
        tillSalesSearch
        tillSaleSell
        tillSaleRefund
        tillSaleReturn
        tillCashbookOpen
        tillCashbookClose
        eventCreate
        sync
        id
      }
    }
  }
`;
